<template>
  <MainHeader :status="status" />
  <div class="contents withdraw-page bg-back">
    <div class="page-ttl">
      <div class="inner">
        <h1>{{ $t("Withdrawal") }}</h1>
      </div>
    </div>
    <div class="box">
      <div class="inner">
        <div class="balance-area">
          <p>{{ $t("BALANCE") }}</p>
          <!-- <div class="label">
            <b>{{ standardToken }}</b>
            <span>{{ $t("CCP") }}</span>
          </div> -->
          <div class="label">
            <b>{{ ap }}</b>
            <span>{{ $t("AP") }}</span>
          </div>
        </div>
        <div class="form-area">
          <p>{{ $t("PROCEDURE") }}</p>
          <div class="notice">
            {{ $t("※ Withdrawals are possible only in USDT (TRC20).") }}
          </div>
          <form>
            <!-- <div class="input-area select">
              <select name="" id="" v-model="AssetName">
                <option value="AP">USD</option>
                <option value="STANDARDTOKEN">CCP</option>
              </select>
            </div> -->
            <div class="input-area">
              <input
                type="text"
                name=""
                :placeholder="$t('WALLET ADDRESS')"
                v-model="address"
              />
            </div>
            <div class="input-area">
              <input
                type="number"
                name=""
                :placeholder="$t('AMOUNT')"
                v-model="addressNumber"
              />
            </div>
            <div class="all-amount">
              <span>{{
                parseFloat(addressNumber - addressNumber * 0.03).toFixed(2)
              }}</span
              >USDT
            </div>
            <div class="all-amount">{{ $t("※ Withdrawal fee is 3%.") }}</div>
            <div class="btn">
              <button
                type="button"
                class="link-over"
                v-on:click="show"
                ref="btnRef"
                disabled
              >
                {{ $t("Withdrawal") }}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <!-- ./ -->
    <vue-final-modal
      name="modal"
      v-model="showModal"
      :resizable="true"
      :adaptive="true"
      :scrollable="true"
      width="100%"
      height="auto"
    >
      <div class="modal-box">
        <p>{{ $t("Are you sure you want to run it?") }}</p>
        <div class="flex">
          <div class="detail">
            <p>{{ $t("TO") }}</p>
            <div class="label">
              {{ this.address }}
            </div>
          </div>
          <div class="detail">
            <p>{{ $t("AMOUNT") }}</p>
            <div v-if="AssetName == 'AP'" class="label amount">
              {{ this.addressNumber }}<span>USD</span>
            </div>
            <div v-else class="label amount">
              {{ this.addressNumber }}<span>CCP</span>
            </div>
          </div>
        </div>
        <div class="btn">
          <button type="button" class="link-over mb-10" v-on:click="hideNo">
            {{ $t("NO") }}
          </button>
        </div>
        <div class="btn">
          <button type="button" class="link-over" v-on:click="hide">
            {{ $t("YES") }}
          </button>
        </div>
      </div>
    </vue-final-modal>
  </div>
  <!-- /.contents -->
  <MainFooter />
</template>
<style lang="less" scoped>
.mb-10 {
  margin-bottom: 10px;
}
</style>
<script>
import MainHeader from "@/components/MainHeader.vue";
import MainFooter from "@/components/MainFooter.vue";
import Withdrawal from "../js/api/withdrawal";
import { i18n } from "@/i18n/i18n";
import Common from "../js/common";
import LoginUser from "@/js/loginUser.js";
export default {
  name: "HarvestView",
  components: {
    MainHeader,
    MainFooter,
  },
  watch: {
    address(value) {
      if (value != "" && this.addressNumber != "") {
        this.$refs.btnRef.disabled = false;
        this.$refs.btnRef.style = "background-color:#7ed7ff !important";
      } else {
        this.$refs.btnRef.style = "background-color:#e3e4e4 !important";
        this.$refs.btnRef.disabled = true;
      }
    },
    addressNumber(value) {
      if (value != "" && this.address != "") {
        this.$refs.btnRef.disabled = false;
        this.$refs.btnRef.style = "background-color:#7ed7ff !important";
      } else {
        this.$refs.btnRef.style = "background-color:#e3e4e4 !important";
        this.$refs.btnRef.disabled = true;
      }
    },
  },
  data() {
    return {
      status: true,
      address: "",
      showModal: false,
      addressNumber: "",
      AssetName: "AP",
      authenticationCode: "",
      ap: LoginUser.getap(),
      standardToken: LoginUser.getStandardToken(),
    };
  },
  mounted() {
    this.$refs.btnRef.style = "background-color:#e3e4e4 !important";
  },
  methods: {
    show: function () {
      this.$vfm.show("modal");
    },
    hide: function () {
      this.withdraw();
      this.$vfm.hide("modal");
    },
    hideNo() {
      this.$vfm.hide("modal");
    },
    withdraw() {
      const data = {
        AssetName: this.AssetName,
        withdrawalAddress: this.address,
        amount: this.addressNumber,
        authenticationCode: this.authenticationCode,
      };
      Withdrawal.addWithdrawalAsync(data)
        .then(() => {
          Common.success(
            i18n.global.t("The withdrawal application has been completed.")
          );
          this.address = "";
          this.addressNumber = "";
          setTimeout(function () {
            window.location.reload();
          }, 2000);
        })
        .finally();
    },
  },
  // mounted: function () {
  //   let firstAddress = this.address.slice(0, 4);
  //   let lastAddress = this.address.slice(-4);
  //   let sliceAddress = firstAddress + "..." + lastAddress;
  //   this.addressNumber = sliceAddress;
  //   console.log(this.addressNumber);
  // },
};
</script>
