<template>
  <MainHeader :status="status" />
  <div class="contents account-page bg-back">
    <div class="page-ttl">
      <div class="inner">
        <h1>{{ $t("ACCOUNT") }}</h1>
      </div>
    </div>
    <div class="form-box">
      <div class="inner">
        <form>
          <div class="wrap">
            <div class="input-area">
              <p>{{ $t("USER NAME") }}</p>
              <div class="input">
                <input
                  type="text"
                  name=""
                  :value="userName"
                  placeholder=""
                  readonly
                />
              </div>
            </div>
          </div>
          <div class="wrap">
            <div class="input-area password">
              <p>{{ $t("Email address") }}</p>
              <div class="input">
                <input
                  type="text"
                  name=""
                  :value="email"
                  placeholder=""
                  readonly
                />
                <span
                  v-if="emailConfirmed"
                  class="link-over"
                  v-on:click="emailShow"
                  >{{ $t("Edit") }}</span
                >
                <span v-else class="link-over mx-w" v-on:click="emailShow">{{
                  $t("CERT")
                }}</span>
              </div>
            </div>
            <!-- <div class="input-area">
              <p>{{ $t("Telephone") }}</p>
              <div class="input">
                <input
                  type="text"
                  name=""
                  :value="phoneNumber"
                  readonly
                  placeholder=""
                />
              </div>
            </div> -->
          </div>
          <div class="wrap">
            <div class="input-area password">
              <p>{{ $t("PASSWORD") }}</p>
              <div class="input">
                <input
                  type="text"
                  name=""
                  value="※※※※※※※※※※"
                  placeholder=""
                  readonly
                />
                <span class="link-over" v-on:click="show">{{
                  $t("Edit")
                }}</span>
              </div>
            </div>
            <div class="input-area">
              <p>{{ $t("Introducer") }}</p>
              <div class="input">
                <input
                  type="text"
                  name=""
                  :value="introducer"
                  readonly
                  placeholder=""
                />
              </div>
            </div>
          </div>
          <!-- <div class="btn">
            <button type="button" class="link-over">DONE</button>
          </div> -->
        </form>
      </div>
    </div>
    <!-- ./ password -->
    <vue-final-modal
      name="modal"
      v-model="showModal"
      :resizable="true"
      :adaptive="true"
      :scrollable="true"
      width="100%"
      height="auto"
    >
      <div class="modal-box">
        <p>{{ $t("Resetting a password") }}</p>
        <div class="input-area">
          <input
            type="password"
            :placeholder="$t('old Password')"
            v-model="oldPassword"
          />
        </div>
        <div class="input-area">
          <input
            type="password"
            :placeholder="$t('new Password')"
            v-model="newPassword"
          />
        </div>
        <div class="input-area">
          <input
            type="password"
            :placeholder="$t('Confirm the new password')"
            v-model="confirmPassword"
          />
        </div>
        <div class="btn">
          <button type="button" class="link-over mb-10" v-on:click="hideNo">
            {{ $t("NO") }}
          </button>
          <button
            type="button"
            class="link-over"
            v-on:click="hide"
            ref="btnRef"
          >
            {{ $t("DONE") }}
          </button>
        </div>
      </div>
    </vue-final-modal>

    <!-- ./ email -->
    <vue-final-modal
      name="modalEmail"
      v-model="showEmail"
      :resizable="true"
      :adaptive="true"
      :scrollable="true"
      width="100%"
      height="auto"
    >
      <div class="modal-box">
        <p v-if="emailConfirmed">{{ $t("Email to modify") }}</p>
        <p v-else>{{ $t("Email authentication") }}</p>
        <div class="input-area" v-if="emailConfirmed">
          <input type="text" :placeholder="$t('EMAIL')" v-model="oldEmail" />
        </div>
        <div class="input-area">
          <input
            type="password"
            :placeholder="$t('PASSWORD')"
            v-model="emailPassword"
          />
        </div>
        <div class="input-area input-auth">
          <span class="div_btn" @click="onEmailSend">{{ $t("SEND") }}</span>
          <input
            type="text"
            :placeholder="$t('VERIFICATION CODE')"
            v-model="emailCode"
          />
        </div>
        <div class="btn">
          <button
            type="button"
            class="link-over mb-10"
            v-on:click="emailCodeClick()"
          >
            {{ $t("NO") }}
          </button>
          <button type="button" class="link-over" v-on:click="hideEmail()">
            {{ $t("DONE") }}
          </button>
        </div>
      </div>
    </vue-final-modal>
  </div>
  <!-- /.contents -->
  <MainFooter />
</template>
<style lang="less" scoped>
.div_btn {
  // line-height: 44px;
  height: 100%;
  position: absolute;
  right: 0px;
  border-radius: 0px 20px 20px 0px;
  background: #1989fa;
  padding: 0px 10px 0px 10px;
  color: #ffffff;
  border-width: 900;
  display: flex;
  align-items: center;
  text-align: center;
}
.input-auth {
  position: relative;
}
.mb-10 {
  margin-bottom: 10px;
}
</style>
<script>
import MainHeader from "@/components/MainHeader.vue";
import MainFooter from "@/components/MainFooter.vue";
import LoginUser from "@/js/loginUser.js";
import Account from "@/js/api/account";
import Common from "@/js/common";
import { Notify } from "vant";
export default {
  name: "AccountView",
  components: {
    MainHeader,
    MainFooter,
  },
  data() {
    return {
      status: true,
      showModal: false,
      showEmail: false,
      userName: LoginUser.getUserName(),
      email: LoginUser.getEmail(),
      introducer: LoginUser.getIntroducer(),
      phoneNumber: LoginUser.getPhoneNumber(),
      emailConfirmed: LoginUser.getEmailConfirmed(),
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
      emailPassword: "",
      emailCode: "",
      newEmail: "",
    };
  },
  watch: {
    oldPassword(value) {
      if (value != "" && this.newPassword != "" && this.confirmPassword != "") {
        this.$refs.btnRef.disabled = false;
        this.$refs.btnRef.style = "background-color:#fff !important";
      } else {
        this.$refs.btnRef.style = "background-color:#e3e4e4 !important";
        this.$refs.btnRef.disabled = true;
      }
    },
    newPassword(value) {
      if (value != "" && this.oldPassword != "" && this.confirmPassword != "") {
        this.$refs.btnRef.disabled = false;
        this.$refs.btnRef.style = "background-color:#fff !important";
      } else {
        this.$refs.btnRef.style = "background-color:#e3e4e4 !important";
        this.$refs.btnRef.disabled = true;
      }
    },
    confirmPassword(value) {
      if (value != "" && this.oldPassword != "" && this.confirmPassword != "") {
        this.$refs.btnRef.disabled = false;
        this.$refs.btnRef.style = "background-color:#fff !important";
      } else {
        this.$refs.btnRef.style = "background-color:#e3e4e4 !important";
        this.$refs.btnRef.disabled = true;
      }
    },
  },
  mounted() {
    this.$refs.btnRef.style = "background-color:#e3e4e4 !important";
    this.$refs.btnRef.disabled = true;
  },
  methods: {
    onPassword() {
      this.oldPassword = "";
      this.newPassword = "";
      this.confirmPassword = "";
    },
    show: function () {
      this.$vfm.show("modal");
    },
    hide: function () {
      let data = {
        currentPassword: this.oldPassword,
        newPassword: this.newPassword,
        confirmPassword: this.confirmPassword,
        authenticationCode: "",
      };
      Account.changePassword(data).then((res) => {
        if (res) {
          Notify({ type: "success", message: "success" });
          this.showPassword = false;
          Common.loggedOut();
        }
      });
      this.$vfm.hide("modal");
    },
    hideNo() {
      this.$vfm.hide("modal");
    },
    emailShow() {
      this.showEmail = true;
    },
    emailCodeClick() {
      this.showEmail = false;
    },
    onEmailSend() {
      if (!this.emailConfirmed) {
        if (this.emailPassword) {
          let data = {
            password: this.emailPassword,
          };
          this.loading = true;
          Account.sendEmailVerificationCodeSelf(data).then((res) => {
            this.loading = false;
            if (res) {
              Notify({
                type: "success",
                message: this.$t(
                  "Verification code has been sent to your email"
                ),
              });
            }
          });
        } else {
          Notify({
            type: "danger",
            message: this.$t("Password is required."),
          });
        }
      } else {
        if (this.oldEmail) {
          Account.isEmailExists(this.oldEmail).then((res) => {
            if (res) {
              if (res.data) {
                Notify({ type: "danger", message: this.$t("Already Exists.") });
              } else {
                if (this.emailPassword) {
                  let data = {
                    email: this.oldEmail,
                    password: this.emailPassword,
                  };
                  // console.log(data);
                  Account.sendEmaileVerificationCode(data).then((res) => {
                    if (res) {
                      Notify({
                        type: "success",
                        message: this.$t(
                          "Verification code has been sent to your email"
                        ),
                      });
                    }
                  });
                } else {
                  Notify({
                    type: "danger",
                    message: this.$t("Password is required."),
                  });
                }
              }
            }
          });
        } else {
          Notify({ type: "danger", message: this.$t("Email is required.") });
        }
      }
    },
    hideEmail() {
      if (!this.emailConfirmed) {
        if (this.emailCode) {
          let data = {
            verificationCode: this.emailCode,
          };
          Account.verifyEmailSelf(data).then((res) => {
            if (res) {
              Notify({ type: "success", message: "success" });
              this.showEmail = false;
              Account.getLoginInfoAsync().then(() => {
                this.username = LoginUser.getUserName();
                this.referralCode = LoginUser.getReferralCode();
                this.email = LoginUser.getEmail();
                this.emailConfirmed = LoginUser.getEmailConfirmed();
              });
            }
          });
        } else {
          Notify({
            type: "danger",
            message: this.$t("Verification Code is required."),
          });
        }
      } else {
        if (this.oldEmail) {
          if (this.emailCode) {
            let data = {
              email: this.oldEmail,
              verificationCode: this.emailCode,
            };
            Account.verifyEmail(data).then((res) => {
              if (res) {
                Notify({ type: "success", message: "success" });
                this.showEmail = false;
                Account.getLoginInfoAsync().then(() => {
                  this.username = LoginUser.getUserName();
                  this.referralCode = LoginUser.getReferralCode();
                  this.email = LoginUser.getEmail();
                  this.emailConfirmed = LoginUser.getEmailConfirmed();
                });
              }
            });
          } else {
            Notify({
              type: "danger",
              message: this.$t("Verification Code is required."),
            });
          }
        } else {
          Notify({ type: "danger", message: this.$t("Email is required.") });
        }
      }
    },
  },
};
</script>
