import Fetch from "@/js/api/fetch";

const Asset = {
  getHistories: (data) =>
    Fetch.get("/Asset/ListPointHistories", {
      params: data,
    }),
  pointToUser: (data) => Fetch.post("/Asset/PointToUser", data),
  pointToPoint: (data) => Fetch.post("/Asset/PointToPoint", data),
  getWithdrawals: (data) =>
    Fetch.get("/Asset/ListWithdrawals", {
      params: data,
    }),
  PostPointToPoint: (data) => Fetch.post("/Asset/PointToPoint", data),
};

export default Asset;
