<template>
  <div class="contents signin-page reset-page">
    <div class="box">
      <div class="inner">
        <h1>{{ $t("Resetting a password") }}</h1>
        <form @submit.prevent>
          <div class="input-area">
            <input
              type="text"
              name=""
              v-model="userEmail"
              :placeholder="$t('Enter your valid address')"
              autocomplete=""
              required
            />
          </div>
          <div class="input-area input-auth">
            <span class="div_btn" @click="sendCode">{{ $t("SEND") }}</span>
            <input
              type="text"
              name=""
              v-model="verificationCode"
              :placeholder="$t('Enter your verification code')"
              autocomplete=""
              required
            />
          </div>
          <div class="input-area">
            <input
              type="password"
              name=""
              v-model="password"
              :placeholder="$t('NEW PASSWORD')"
              autocomplete=""
              required
            />
          </div>
          <div class="input-area">
            <input
              type="password"
              name=""
              v-model="confirmPassword"
              :placeholder="$t('Enter the new password again')"
              autocomplete=""
              required
            />
          </div>
          <div class="form-btn">
            <button v-on:click="submit" type="button" class="link-over">
              {{ $t("DONE") }}
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
  <!-- /.contents -->
</template>
<style lang="less" scoped>
.div_btn {
  // line-height: 44px;
  height: 100%;
  position: absolute;
  right: 0px;
  border-radius: 0px 6px 6px 0px;
  background: #1989fa;
  padding: 0px 10px 0px 10px;
  color: #ffffff;
  border-width: 900;
  display: flex;
  align-items: center;
  text-align: center;
}
.input-auth {
  position: relative;
}
</style>
<script>
import Account from "@/js/api/account";
import { Notify } from "vant";
export default {
  name: "PasswordInput",
  components: {},
  data() {
    return {
      userName: "",
      userEmail: "",
      verificationCode: "",
      password: "",
      confirmPassword: "",
      loading: false,
    };
  },
  created() {
    this.userName = this.$route.query.username;
  },
  methods: {
    submit() {
      this.loading = true;
      Account.resetPassword({
        username: this.userName,
        email: this.userEmail,
        verificationCode: this.verificationCode,
        newPassword: this.password,
        confirmPassword: this.confirmPassword,
        NewSecurityPassword: this.password,
        ConfirmSecurityPassword: this.confirmPassword,
      })
        .then((response) => {
          if (response) {
            Notify({ type: "success", message: "success" });
            this.$router.push("/signin");
          }
        })
        .finally(() => (this.loading = false));
    },
    sendCode() {
      if (this.userEmail) {
        this.loading = true;
        let params = {};
        params.userName = this.userName;
        params.email = this.userEmail;
        Account.validateEmailAndUserName(params).then((res) => {
          this.loading = false;
          if (res) {
            this.loading = true;
            Account.sendResetPasswordCodeByEmail(
              this.userEmail,
              this.userName
            ).then((res) => {
              this.loading = false;
              if (res) {
                Notify({
                  type: "success",
                  message: this.$t(
                    "Verification code has been sent to your email"
                  ),
                });
              }
            });
          }
        });
      } else {
        Notify({
          type: "danger",
          message: this.$t("{item} is required.", {
            item: this.$t("YOUR EMAIL"),
          }),
        });
      }
    },
  },
};
</script>
