<template>
  <MainHeader :status="status" />
  <div class="contents item-page wallet-list bg-back">
    <div class="list-box">
      <div class="inner">
        <div class="ttl">
          <h2>{{ $t("MY NFT") }}</h2>
          <div class="tab">
            <span
              v-for="(item, i) in BonusSettingIdList"
              :key="i"
              class="link-over"
              v-on:click="isSelect(item.id, Number(i) + 1 + '')"
              v-bind:class="{
                active: isActive === Number(i) + 1 + '',
              }"
              >${{ item.min }}</span
            >
          </div>
        </div>
        <div class="tab-contents active">
          <ul class="list">
            <li
              v-for="(item, i) in itemList"
              :key="i"
              class="active"
              @click="show(i, item.url)"
            >
              <router-link
                :to="`/single?id=` + item.bonusSettingId"
                class="link-over"
              >
                <div class="img">
                  <img :src="item.url" alt="" />
                </div>
                <div class="txt">
                  <!-- <p>{{ item.name }}</p> -->
                  <p>
                    $<span>{{ item.min }}</span>
                  </p>
                </div>
              </router-link>
              <div
                class="active-flag"
                v-if="item.isStaking || item.transactionStatus != null"
              >
                <div
                  class="icon"
                  v-if="item.isStaking || item.transactionStatus == 'Completed'"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    width="194"
                    height="194"
                    viewBox="0 0 194 194"
                  >
                    <defs>
                      <clipPath id="clip-path">
                        <rect
                          id="長方形_439"
                          data-name="長方形 439"
                          width="194"
                          height="194"
                          transform="translate(1187.406 635.422)"
                          fill="#fff"
                          stroke="#707070"
                          stroke-width="1"
                        />
                      </clipPath>
                    </defs>
                    <g
                      id="マスクグループ_25"
                      data-name="マスクグループ 25"
                      transform="translate(-1187.406 -635.422)"
                      clip-path="url(#clip-path)"
                    >
                      <g
                        id="_饑1"
                        data-name="ﾑ饑1"
                        transform="translate(1198.615 635.422)"
                      >
                        <path
                          id="パス_29"
                          data-name="パス 29"
                          d="M85.606,81.683h.366c7.814,0,14.15-4.267,14.15-9.53V9.53c0-5.264-6.334-9.53-14.15-9.53h-.366c-7.814,0-14.15,4.267-14.15,9.53V72.155c0,5.264,6.334,9.53,14.15,9.53Z"
                          fill="#fff"
                        />
                        <path
                          id="パス_30"
                          data-name="パス 30"
                          d="M134.707,37.765a11.488,11.488,0,0,0-18.053,9.426v.452a11.488,11.488,0,0,0,4.892,9.406,62.443,62.443,0,1,1-71.511,0,11.488,11.488,0,0,0,4.892-9.406v-.452a11.488,11.488,0,0,0-18.053-9.426,85.791,85.791,0,1,0,97.832,0Z"
                          fill="#fff"
                        />
                      </g>
                    </g>
                  </svg>
                </div>
                <div v-else-if="item.transactionStatus == 'Pending'">
                  <span class="font-span">{{ $t("ON DISPLAY") }}</span>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <!-- ./list-box -->
    <!-- ./list-box -->
    <div class="fixed-btn">
      <router-link to="/market" class="link-over">{{
        $t("Put up an item")
      }}</router-link>
      <router-link to="/staking" class="link-over"
        >{{ $t("Go to Staking") }}!!</router-link
      >
    </div>
    <vue-final-modal
      name="Modal"
      v-model="Modal"
      :resizable="true"
      :adaptive="true"
      :scrollable="true"
      width="100%"
      height="auto"
    >
      <div class="modal-box img-modal">
        <div class="img">
          <img :src="modalImgUrl" alt="" />
        </div>
        <div class="btn">
          <button type="button" class="link-over not-btn" v-on:click="hide">
            {{ $t("Close") }}
          </button>
        </div>
      </div>
    </vue-final-modal>
  </div>
  <!-- /.contents -->
  <MainFooter />
</template>

<script>
import MainHeader from "@/components/MainHeader.vue";
import MainFooter from "@/components/MainFooter.vue";
// import LoginUser from "@/js/loginUser.js";
import Plate from "@/js/api/plate";
export default {
  name: "WalletView",
  components: {
    MainHeader,
    MainFooter,
  },
  data() {
    return {
      status: true,
      isActive: "1",
      BonusSettingIdList: [],
      itemList: [],
      isStaking: true,
      Modal: false,
      modalImgUrl: null,
    };
  },
  created() {
    this.createList();
  },
  methods: {
    // 获取数据
    createList() {
      Plate.getListPackageInfo()
        .then((response) => {
          this.BonusSettingIdList = response.data.detail;
          console.log(this.BonusSettingIdList);
        })
        .finally(() => (this.loading = false));

      let params = {
        BonusSettingId: "Plage1",
        index: 0,
        take: 10000,
      };
      Plate.ListMyNft(params)
        .then((response) => {
          this.itemList = response.data.detail;
          console.log(this.itemList);
        })
        .finally(() => (this.loading = false));
    },
    isSelect: function (id, num) {
      this.isActive = num + "";
      let params = {
        BonusSettingId: id,
        index: 0,
        take: 10000,
      };
      Plate.ListMyNft(params)
        .then((response) => {
          this.itemList = response.data.detail;
        })
        .finally(() => (this.loading = false));
    },
    show: function (i, ImgUrl) {
      this.modalImgUrl = ImgUrl;
      this.$vfm.show("Modal");
    },
    hide: function () {
      this.$vfm.hide("Modal");
    },
  },
};
</script>
<style lang="less" scoped>
.font-span {
  color: #fff;
  font-weight: 600;
  width: 100%;
  text-align: center;
  font-size: 16px;
  letter-spacing: 1px;
}
</style>
