<template>
  <div class="contents signin-page">
    <div class="box">
      <div class="inner">
        <h1>{{ $t("SIGN IN") }}</h1>
        <div class="ttl">
          <p>
            {{ $t("If you are a first-time user") }}
            <br />
            {{ $t("please create a user") }}
            <router-link to="/signup" class="link-over">{{
              $t("SIGN UP")
            }}</router-link>
            .
          </p>
        </div>
        <form @submit.prevent>
          <div class="input-area">
            <input
              type="text"
              name=""
              v-model="username"
              :placeholder="$t('USER NAME')"
              required
            />
          </div>
          <div class="input-area">
            <input
              type="password"
              name=""
              v-model="password"
              :placeholder="$t('PASSWORD')"
              required
            />
          </div>
          <!-- <div class="remember">
            <input type="checkbox" name="" id="check" />
            <label for="check">パスワードを記憶する</label>
          </div> -->
          <div class="form-btn">
            <button v-on:click="login" type="button" class="link-over">
              {{ $t("NEXT") }}
            </button>
          </div>
        </form>
        <div class="forgot-link">
          <router-link to="/passwordreset" class="link-over">
            <!-- <span><img src="img/password-link-icon-img.svg" alt="" /></span> -->
            {{ $t("Forgot your password?") }}
          </router-link>
        </div>
      </div>
    </div>
  </div>

  <!-- /.contents -->
</template>

<script>
// import Header from "@/components/Header.vue";
// import HeaderMenu from "@/components/HeaderMenu.vue";
// import Footer from "@/components/Footer.vue";
import { ref, onMounted, watch } from "vue";
import Common from "../js/common";
import Account from "../js/api/account";
import { i18n } from "../i18n/i18n";
import { useRouter } from "vue-router";
export default {
  name: "Signin_vue",
  components: {
    // Header,
    // HeaderMenu,
    // Footer,
  },
  methods: {
    onSignUp() {
      this.$router.push({ name: "Signup" });
    },
  },
  setup() {
    const username = ref("");
    const password = ref("");
    const loading = ref(false);
    const twoFactor = ref(false);
    let twoFactorToken = "";
    console.log(twoFactorToken);
    const authenticatorCode = ref("");
    const authenticatorCodeLength = 6;

    onMounted(() => {
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", vh + "px");

      var ua = navigator.userAgent.toLowerCase();
      var isiOS = ua.indexOf("iphone") > -1 || ua.indexOf("ipad") > -1;
      if (isiOS) {
        var viewport = document.querySelector('meta[name="viewport"]');
        if (viewport) {
          var viewportContent = viewport.getAttribute("content");
          viewport.setAttribute(
            "content",
            viewportContent + ", user-scalable=no"
          );
        }
      }
    });

    const router = useRouter();
    const login = () => {
      if (username.value && password.value) {
        const data = {
          username: username.value,
          password: password.value,
          grant_type: "Username_password",
          recaptchaResponse: "dummy",
        };
        loading.value = true;
        Account.loginAsync(data)
          .then((response) => {
            if (response.data.twoFactorToken) {
              twoFactor.value = true;
              twoFactorToken = response.data.twoFactorToken;
            } else {
              loggedIn(response.data);
            }
          })
          .finally(() => {
            loading.value = false;
          });
      }
    };

    // const challengeAuthenticatorCode = () => {
    //   if (authenticatorCode.value.length === authenticatorCodeLength) {
    //     let twoFactorData = {
    //       twoFactorToken,
    //       authenticatorCode: authenticatorCode.value,
    //     };
    //     Account.twoFactorAsync(twoFactorData)
    //       .then((response) => {
    //         loggedIn(response.data);
    //       })
    //       .finally(() => {
    //         loading.value = false;
    //       });
    //   }
    // };

    const loggedIn = (data) => {
      Common.loggedIn(data.access_token, data.refresh_token);
      Common.success(i18n.global.t("Login succeed."));
      router.push("/");
    };

    // const backToLogin = () => {
    //   authenticatorCode.value = "";
    //   twoFactor.value = false;
    // };

    watch();
    // (authenticatorCode,
    // () => {
    //   if (authenticatorCode.value.length === authenticatorCodeLength) {
    //     challengeAuthenticatorCode();
    //   }
    // })

    return {
      username,
      password,
      loading,
      twoFactor,
      authenticatorCode,
      authenticatorCodeLength,
      login,
      // challengeAuthenticatorCode,
      // backToLogin,
    };
  },
};
</script>
