<template>
  <MainHeader :status="status" />
  <div class="contents item-page bg-img">
    <div class="fv">
      <div class="inner">
        <div class="list-single">
          <div class="single-img">
            <div class="img">
              <img :src="headListItem.url" alt="" />
              <div class="txt">
                <!-- <p>ITEM NAME</p> -->
                <p>
                  $<span>{{ this.headListItem.min }}</span>
                </p>
              </div>
            </div>
          </div>
          <div class="detail">
            <div class="btn">
              <button type="button" class="link-over" v-on:click="showBuy">
                {{ $t("To buy") }}
              </button>
            </div>
            <!-- <div class="link">
              <router-link to="/rate" class="link-over">{{
                $t("About interest rates")
              }}</router-link>
            </div> -->
          </div>
        </div>
      </div>
    </div>
    <!-- ./fv -->
    <div class="rate-box">
      <div class="inner">
        <!-- $1,000 -->
        <div class="box">
          <h2>${{ this.headListItem.min }}</h2>
          <!-- <div class="wrap">
            <div class="scroll sp-only">
              <perfect-scrollbar>
                <div class="tab-area">
                  <span
                    class="tab link-over"
                    v-on:click="isSelect1('1')"
                    v-bind:class="{
                      active: isActive1 === '1',
                    }"
                    >{{ $t("In case of holding 1 card") }}</span
                  >
                  <span
                    v-if="this.headListItem.min != 50000"
                    class="tab link-over"
                    v-on:click="isSelect1('2')"
                    v-bind:class="{
                      active: isActive1 === '2',
                    }"
                    >{{
                      $t("In case of holding 5 cards" + this.headListItem.id)
                    }}</span
                  >
                  <span
                    class="tab link-over"
                    v-on:click="isSelect1('3')"
                    v-bind:class="{
                      active: isActive1 === '3',
                    }"
                  >
                    <span v-if="this.headListItem.min != 50000">{{
                      $t("In case of holding 10 cards")
                    }}</span>
                    <span v-else>{{
                      $t("In case of holding 1 rare card")
                    }}</span>
                  </span>
                  <span
                    class="tab link-over"
                    v-on:click="isSelect1('4')"
                    v-bind:class="{
                      active: isActive1 === '4',
                    }"
                  >
                    <span v-if="this.headListItem.min != 50000">{{
                      $t("In case of holding a rare card")
                    }}</span>
                    <span v-else>{{
                      $t("In case of holding 2 rare cards")
                    }}</span>
                  </span>
                </div>
              </perfect-scrollbar>
            </div>
            <div class="label-list">
              <div
                class="label link-over"
                v-on:click="isSelect1('1')"
                v-bind:class="{
                  active: isActive1 === '1',
                }"
              >
                <p>{{ $t("In case of holding 1 card") }}</p>
                <span>{{
                  $t(this.headListItem.explain1?.rate + "% monthly dividend")
                }}</span>
              </div>
              <div
                v-if="this.headListItem.min != 50000"
                class="label link-over"
                v-on:click="isSelect1('2')"
                v-bind:class="{
                  active: isActive1 === '2',
                }"
              >
                <p>
                  {{ $t("In case of holding 5 cards" + this.headListItem.id) }}
                </p>
                <span>{{
                  $t(this.headListItem.explain2?.rate + "% monthly dividend")
                }}</span>
              </div>
              <div
                class="label link-over"
                v-on:click="isSelect1('3')"
                v-bind:class="{
                  active: isActive1 === '3',
                }"
              >
                <p v-if="this.headListItem.min != 50000">
                  {{ $t("In case of holding 10 cards") }}
                </p>
                <p v-else>
                  {{ $t("In case of holding 1 rare card") }}
                </p>

                <span v-if="this.headListItem.min != 50000">{{
                  $t(this.headListItem.explain3?.rate + "% monthly dividend")
                }}</span>
                <span v-else>{{ $t("5% monthly dividend") }}</span>
              </div>
              <div
                class="label link-over"
                v-on:click="isSelect1('4')"
                v-bind:class="{
                  active: isActive1 === '4',
                }"
              >
                <p v-if="this.headListItem.min != 50000">
                  {{ $t("In case of holding a rare card") }}
                </p>
                <p v-else>{{ $t("In case of holding 2 rare cards") }}</p>

                <span v-if="this.headListItem.min != 50000">{{
                  $t("2% monthly dividend" + this.headListItem.id)
                }}</span>
                <span v-else>{{ $t("7% monthly dividend") }}</span>
              </div>
            </div>
            <div class="img-list" v-if="isActive1 === '1'">
              <p>
                {{
                  $t(
                    "If you have staked any of the following NFTs, you will receive a monthly dividend of " +
                      this.headListItem.explain1?.rate +
                      "%."
                  )
                }}
              </p>
              <ul class="list">
                <li v-for="(item, i) in headListItem.explain1?.urls" :key="i">
                  <img :src="item.url" alt="" />
                </li>
              </ul>
            </div>
            <div
              class="img-list"
              v-if="isActive1 === '2' && this.headListItem.min != 50000"
            >
              <p
                v-if="
                  this.headListItem.id == 'Plage2' ||
                  this.headListItem.id == 'Plage4'
                "
              >
                {{
                  $t(
                    "If you have staked the following two NFTs, you will receive a monthly dividend of " +
                      this.headListItem.explain2?.rate +
                      "% on each of them. In addition, all other $" +
                      this.headListItem?.min +
                      "NFTs you have staked will also receive a monthly dividend of " +
                      this.headListItem.explain2?.rate +
                      "%."
                  )
                }}
              </p>
              <p v-else>
                {{
                  $t(
                    "If you have staked the following five NFTs, you will receive a monthly dividend of " +
                      this.headListItem.explain2?.rate +
                      "% on each of them. In addition, all other $" +
                      this.headListItem?.min +
                      "NFTs you have staked will also receive a monthly dividend of " +
                      this.headListItem.explain2?.rate +
                      "%."
                  )
                }}
              </p>
              <ul class="list">
                <li v-for="(item, i) in headListItem.explain2?.urls" :key="i">
                  <img :src="item.url" alt="" />
                </li>
              </ul>
            </div>
            <div class="img-list" v-if="isActive1 === '3'">
              <p v-if="this.headListItem.min != 50000">
                {{
                  $t(
                    "If you have staked all 10 NFTs, you will receive a monthly dividend of " +
                      this.headListItem.explain3?.rate +
                      "% each.In addition, all other $" +
                      this.headListItem?.min +
                      " NFTs you have staked will also receive a monthly dividend of " +
                      this.headListItem.explain3?.rate +
                      "%."
                  )
                }}
              </p>
              <p v-else>
                {{
                  $t(
                    "If you have staked 1 rare card, you will receive a monthly dividend of 5%. In addition, all other $50,000 NFTs that you have staked will each receive a monthly dividend of 5%."
                  )
                }}
              </p>
              <ul class="list" v-if="this.headListItem.min != 50000">
                <li v-for="(item, i) in headListItem.explain3?.urls" :key="i">
                  <img :src="item.url" alt="" />
                </li>
              </ul>
              <ul class="list center" v-else>
                <li>
                  <img src="../assets/img/rate-r-img.png" alt="" />
                </li>
              </ul>
            </div>
            <div class="img-list" v-if="isActive1 === '4'">
              <p v-if="this.headListItem.min != 50000">
                {{
                  $t(
                    "If you have staked a rare card, you will receive a monthly dividend of 3%.In addition, all other $" +
                      this.headListItem?.min +
                      " NFTs that you have staked will each receive a monthly dividend of 3%." +
                      this.headListItem?.id
                  )
                }}
              </p>
              <p v-else>
                {{
                  $t(
                    "If you have staked 2 rare cards, you will receive a monthly dividend of 7%. In addition, all other $50000 NFTs that you have staked will each receive a monthly dividend of 7%."
                  )
                }}
              </p>

              <ul class="list center" v-if="this.headListItem.min != 50000">
                <li>
                  <img src="../assets/img/rate-r-img.png" alt="" />
                </li>
              </ul>
              <ul class="list center" v-else>
                <li>
                  <img src="../assets/img/rate-r-img.png" alt="" />
                </li>
                <li>
                  <img src="../assets/img/rate-r-img.png" alt="" />
                </li>
              </ul>
            </div>
          </div> -->
        </div>
        <!-- ./box $1,000 -->
      </div>
    </div>
    <!-- ./rate-box -->
    <div class="sp-only sp-btn-wrap">
      <div class="inner">
        <div class="btn">
          <button type="button" class="link-over" v-on:click="showBuy">
            {{ $t("To buy") }}
          </button>
        </div>
        <!-- <div class="link">
          <router-link to="/rate" class="link-over">{{
            $t("About interest rates")
          }}</router-link>
        </div> -->
      </div>
    </div>
    <!-- ./sp-btn-wrap -->
    <div class="list-box">
      <div class="inner">
        <h2>{{ $t("CONTENT") }}</h2>
        <ul class="list">
          <li v-for="(item, i) in itemList" :key="i">
            <router-link :to="`/single?id=` + item.id" class="link-over">
              <div class="img">
                <img :src="item.url" alt="" />
              </div>
              <div class="txt">
                <p>
                  $<span>{{ item.min }}</span>
                </p>
              </div>
            </router-link>
          </li>
        </ul>
      </div>
    </div>
    <!-- ./list-box -->
    <vue-final-modal
      name="buyModal"
      v-model="buyModal"
      :resizable="true"
      :adaptive="true"
      :scrollable="true"
      width="100%"
      height="auto"
    >
      <div class="modal-box buy-modal">
        <div class="detail">
          <div class="img">
            <img :src="this.headListItem.url" alt="" />
          </div>
          <div class="txt">
            <div class="price" v-if="isActive === '1'">
              {{ this.headListItem.min }}<span><b>USD</b><br />(CCT)</span>
            </div>
            <div class="price" v-if="isActive === '2'">
              {{ Number(this.headListItem.min)
              }}<span><b>CCT</b><br />(USD)</span>
            </div>
            <div class="tab-area">
              <span
                class="tab link-over"
                v-on:click="isSelect('1')"
                v-bind:class="{
                  active: isActive === '1',
                }"
                >USD</span
              >
              <span
                class="tab link-over"
                v-on:click="isSelect('2')"
                v-bind:class="{
                  active: isActive === '2',
                }"
                >CCT</span
              >
            </div>
            <div class="balance" v-if="isActive === '1'">
              {{ $t("BALANCE") }} {{ ap }} USD
            </div>
            <div class="balance" v-if="isActive === '2'">
              {{ $t("BALANCE") }} {{ rp }} CCT
            </div>
            <div class="input-area" v-if="isActive === '1'">
              <input
                type="number"
                name=""
                :placeholder="$t('Amount')"
                @blur="usdBlur"
                v-model="usd"
              />
              <span>USD</span>
            </div>
            <div class="input-area" v-if="isActive === '2'">
              <input
                type="number"
                name=""
                @blur="cctBlur"
                :placeholder="$t('Amount')"
                v-model="cct"
              />
              <span>CCT</span>
            </div>
          </div>
        </div>
        <div class="ttl">
          {{
            $t(
              "The NFT you purchase will be randomly selected out of 10 types."
            )
          }}
        </div>
        <div class="btn">
          <button type="button" class="link-over not-btn" v-on:click="hideBuy">
            {{ $t("NO") }}
          </button>
          <button
            type="button"
            ref="btnRef"
            class="link-over buy-btn"
            v-on:click="showComplete"
          >
            {{ $t("Buy now") }}
          </button>
        </div>
      </div>
    </vue-final-modal>
    <div class="loading-box" v-if="isLoading">
      <div class="bg-img"></div>
      <div class="box">
        <div>
          <img src="../assets/img/mouse-img.png" alt="" />
        </div>
        <div>
          <img src="../assets/img/mouse-img2.png" alt="" />
        </div>
        <div>
          <img src="../assets/img/mouse-img3.png" alt="" />
        </div>
        <div>
          <img src="../assets/img/mouse-img4.png" alt="" />
        </div>
        <div>
          <img src="../assets/img/mouse-img.png" alt="" />
        </div>
        <div>
          <img src="../assets/img/mouse-img2.png" alt="" />
        </div>
        <div>
          <img src="../assets/img/mouse-img3.png" alt="" />
        </div>
        <div>
          <img src="../assets/img/mouse-img4.png" alt="" />
        </div>
        <div>
          <img src="../assets/img/mouse-img.png" alt="" />
        </div>
        <div>
          <img src="../assets/img/mouse-img2.png" alt="" />
        </div>
        <div>
          <img src="../assets/img/mouse-img3.png" alt="" />
        </div>
        <div>
          <img src="../assets/img/mouse-img4.png" alt="" />
        </div>
        <div>
          <img src="../assets/img/mouse-img.png" alt="" />
        </div>
        <div>
          <img src="../assets/img/mouse-img2.png" alt="" />
        </div>
        <div>
          <img src="../assets/img/mouse-img3.png" alt="" />
        </div>
        <div>
          <img src="../assets/img/mouse-img4.png" alt="" />
        </div>
        <div>
          <img src="../assets/img/mouse-img.png" alt="" />
        </div>
        <div>
          <img src="../assets/img/mouse-img2.png" alt="" />
        </div>
        <div>
          <img src="../assets/img/mouse-img3.png" alt="" />
        </div>
        <div>
          <img src="../assets/img/mouse-img4.png" alt="" />
        </div>
      </div>
    </div>
    <vue-final-modal
      name="completeModal"
      v-model="completeModal"
      :resizable="true"
      :adaptive="true"
      :scrollable="true"
      width="100%"
      height="auto"
    >
      <div class="modal-box complete-modal">
        <div class="txt">Congratulation!!</div>
        <div class="img">
          <img :src="succerImg" alt="" />
        </div>
        <div class="btn">
          <router-link
            to="./wallet"
            class="link-over"
            v-on:click="hideComplete"
            >{{ $t("MY NFT") }}</router-link
          >
          <router-link
            :to="`./single?id=` + bonusSettingId"
            class="link-over mt-20"
            v-on:click="hideCompleteHide"
          >
            {{ $t("Close") }}
          </router-link>
        </div>
        <div class="balloons">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </vue-final-modal>
  </div>
  <!-- /.contents -->
  <MainFooter />
</template>

<style lang="less" scoped>
.Rtp_input {
  background-color: #7ed7ff;
  border-radius: 10px;
  border: 1px solid white;
  color: white;
}
.usd_input {
  background-color: #7ed7ff;
  border: 0px;
}
.usd {
  color: white;
  font-weight: 900;
}
.rtp_span {
  font-weight: 900;
  color: white;
  font-size: 14px;
}
.Rtp {
  font-weight: 900;
}
.balance {
  margin-top: 10px;
}
.div_NO {
  display: inline-block;
  width: 160px;
  padding: 10px 10px;
  border-radius: 30px;
  font-weight: 600;
  letter-spacing: 1px;
  margin: 0 10px;
  color: #fff;
  margin-bottom: 10px;
  border: 1px solid #fff;
}
.mt-20 {
  margin-top: 10px !important;
}
</style>
<script>
import MainHeader from "@/components/MainHeader.vue";
import MainFooter from "@/components/MainFooter.vue";
import Plate from "@/js/api/plate";
import LoginUser from "@/js/loginUser.js";
import Definitions from "@/js/definitions";
import { i18n } from "@/i18n/i18n";
import Common from "../js/common";
export default {
  name: "SingleView",
  components: {
    MainHeader,
    MainFooter,
  },
  data() {
    return {
      status: false,
      buyModal: false,
      completeModal: false,
      itemList: [],
      headListItem: {}, //上面显示的数据
      footListItem: [], //下面显示的数据
      succerImg: null, //购买成功图片
      ap: LoginUser.getap(), //USD点数
      rp: LoginUser.getRp(),
      bonusSettingId: null,
      cct: 0,
      usd: null, //剩余
      isActive: "1",
      userinfo: null,
      isLoading: false,
      isActive1: "1",
    };
  },
  watch: {
    cct(value) {
      if (Number(value) > Number(this.headListItem.min) || Number(value) < 0) {
        this.$refs.btnRef.style = "background-color:#e3e4e4 !important";
        this.$refs.btnRef.disabled = true;
      } else {
        this.$refs.btnRef.disabled = false;
        this.$refs.btnRef.style = "background-color:#fff !important";
      }
    },
    usd(value) {
      if (Number(value) > Number(this.headListItem.min) || Number(value) < 0) {
        this.$refs.btnRef.style = "background-color:#e3e4e4 !important";
        this.$refs.btnRef.disabled = true;
      } else {
        this.$refs.btnRef.disabled = false;
        this.$refs.btnRef.style = "background-color:#fff !important";
      }
    },
  },
  created() {
    this.createList();
    this.getListPackageInfo();
  },
  methods: {
    // 获取CONTENT区域四个档区的数据
    getListPackageInfo() {
      Plate.getListPackageInfo()
        .then((response) => {
          if (response.status == 200) {
            this.itemList = this.contentList(response.data.detail);
          }
        })
        .finally(() => (this.loading = false));
    },
    // 判断把当前页面档区的价位数组给去掉
    contentList(res) {
      let token = localStorage.getItem("accessToken");
      if (token != null) {
        this.status = true;
      }
      for (let i = 0; i < res.length; i++) {
        if (res[i].id == this.bonusSettingId) {
          res.splice(i, 1);
        }
      }
      return res;
    },
    isSelect: function (num) {
      this.isActive = num;
    },
    isSelect1: function (num) {
      this.isActive1 = num;
    },
    usdBlur() {
      this.cct = Number(this.headListItem.min) - Number(this.usd);
    },
    cctBlur() {
      this.usd = Number(this.headListItem.min) - Number(this.cct);
    },
    // 获取数据
    createList() {
      this.bonusSettingId = this.$route.query.id;
      Plate.GetPackageInfo(this.$route.query.id)
        .then((response) => {
          // this.itemList = response.data.detail;
          this.headListItem = response.data.header;
          this.footListItem = response.data.detail;
          this.usd = response.data.header.min;
        })
        .finally(() => (this.loading = false));
    },
    // 点击购物弹出购买详细
    showBuy: function () {
      const accessToken = localStorage.getItem(
        Definitions.StorageKeys.accessToken
      );
      if (accessToken != null) {
        this.$vfm.show("buyModal");
        this.userinfo = LoginUser.getUserInfo();
      } else {
        this.$router.push("/signin");
      }
    },
    hideBuy: function () {
      this.$vfm.hide("buyModal");
    },
    //点击购物发请求
    showComplete: function () {
      let params = {
        DeductionRP: this.cct,
        BonusSettingId: this.bonusSettingId,
      };

      if (
        Number(this.usd) > Number(this.ap) ||
        Number(this.cct) > Number(this.rp)
      ) {
        Common.error(i18n.global.t("not sufficient funds."));
        return;
      }
      Plate.ActivePlate(params)
        .then((response) => {
          this.$vfm.show("completeModal");
          this.$vfm.hide("buyModal");
          if (response.status == 200) {
            this.succerImg = response.data.nftUrl;
            this.$vfm.hide("buyModal");
            this.isLoading = true;
            setTimeout(() => {
              this.isLoading = false;
              this.$vfm.show("completeModal");
            }, 5000);
          }
        })
        .finally(() => (this.loading = false));
    },
    hideComplete: function () {
      // window.location.reload();
      this.$vfm.hide("completeModal");
    },
    hideCompleteHide: function () {
      this.$vfm.hide("completeModal");
      window.location.reload();
    },
  },
};
</script>
