import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import { i18n } from "./i18n/i18n";
import Vant from "vant";
import "vant/lib/index.css";
import store from "./store";
import VueClipboard from "vue3-clipboard";
// import Clipboard from "v-clipboard3";
import { vfmPlugin } from "vue-final-modal";
import Common from "./js/common";
import Definitions from "./js/definitions";
import Account from "./js/api/account";
const app = createApp(App);
app.use(VueClipboard, {
  autoSetContainer: true,
  appendToBody: true,
});
app.use(
  vfmPlugin({
    key: "$vfm",
    componentName: "VueFinalModal",
    dynamicContainerName: "ModalsContainer",
  })
);
router.beforeEach((to, from, next) => {
  const token = localStorage.getItem(Definitions.StorageKeys.accessToken);
  let anonymousPages = [
    "SignIn",
    "SignUp",
    "Item",
    "Single",
    "PasswordReset",
    "PasswordInput",
  ];
  if (to.name == "MypageView" && Common.isEmpty(token)) {
    return next({
      path: "/signin",
    });
  }
  if (anonymousPages.indexOf(to.name) >= 0 && Common.isEmpty(token)) {
    return next();
  } else {
    if (Common.isEmpty(token)) {
      return next({
        path: "/signin",
      });
    } else {
      return Account.getLoginInfoAsync().then(() => next());
    }
  }

  // const accessToken = localStorage.getItem(Definitions.StorageKeys.accessToken);
  // let anonymousPages = [
  //   "Signin",
  //   "Signup",
  //   "Forgot",
  //   "ForgotPassword",
  //   "ForgotPassword2",
  //   "ResetPassword",
  //   "DonePassword",
  //   "ForgotAccount",
  //   "ForgotAccount2",
  //   "DoneAccount",
  //   "Maintenance",
  // ];
  // if (anonymousPages.indexOf(to.name) >= 0) {
  //   return next();
  // }
  // // if (to.name === "Signin" || to.name === "Signup") {
  // //   return next();
  // // }
  // if (Common.isEmpty(accessToken)) {
  //   return next({
  //     path: "/signin",
  //   });
  // } else {
  //   return Account.getLoginInfoAsync().then(() => next());
  // }
});
// 浏览器大小被重置
window.onresize = () => {
  store.commit("setScreenWidth", document.body.clientWidth);
};
app.use(store);
app.use(i18n);
app.use(Vant);
app.use(router);
app.use(Common);
app.use(Definitions);
app.use(Account);
// app.use(Clipboard);
app.mount("#app");
