<template>
  <MainHeader :status="status" />
  <div class="contents deposit-page bg-back">
    <div class="page-ttl">
      <div class="inner">
        <h1>{{ $t("DEPOSIT") }}</h1>
        <div class="link">
          <span
            class="link-over"
            v-on:click="isTabSelect('1')"
            v-bind:class="{
              active: isTabActive === '1',
            }"
            >TRC20</span
          >
          <span
            class="link-over"
            v-on:click="isTabSelect('2')"
            v-bind:class="{
              active: isTabActive === '2',
            }"
            >BEP20</span
          >
        </div>
      </div>
    </div>
    <div v-if="isTabActive === '1'">
      <div class="box">
        <div class="inner">
          <div class="balance-area">
            <p>{{ $t("BALANCE") }}</p>
            <div class="label">
              <b>{{ this.NumUSD }}</b>
              <span>USD</span>
            </div>
          </div>
          <div class="address-area">
            <p>{{ $t("ADDRESS") }}</p>
            <div class="label">
              <div
                class="create link-over"
                v-on:click="QrCodeshowTrc20"
                v-if="isActive === '1'"
              >
                <img src="../assets/img/plus-icon-img.svg" alt="" />
                <p>{{ $t("CREATE ADDRESS") }}</p>
              </div>
              <div class="input-area" v-if="isActive === '2'">
                <input
                  type="text"
                  name=""
                  v-model="inputMoneyTRC"
                  :placeholder="$t('USD amount you wish to charge')"
                />
                <p>
                  <span>
                    {{
                      parseFloat(inputMoneyTRC / (1 - usdtFeeRate)).toFixed(4)
                    }} </span
                  >USDT
                </p>
              </div>
              <div class="img" v-if="isActive === '2'">
                <QRCodeVue3
                  :width="200"
                  :height="200"
                  :value="addressTRC"
                  :qrOptions="{
                    typeNumber: 8,
                    mode: 'Byte',
                    errorCorrectionLevel: 'H',
                  }"
                  :dotsOptions="{
                    type: 'classy',
                  }"
                />
              </div>
              <div class="input" v-if="isActive === '2'">
                <input type="text" :value="addressTRC" readonly />
                <span
                  class="link-over"
                  v-clipboard:copy="addressTRC"
                  v-clipboard:success="onCopySuccess"
                  v-clipboard:error="onCopyError"
                >
                  <img src="../assets/img/copy-icon2-img.svg" alt="" />
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="isTabActive === '2'">
      <div class="box">
        <div class="inner">
          <div class="balance-area">
            <p>{{ $t("BALANCE") }}</p>
            <div class="label">
              <b>{{ this.NumUSD }}</b>
              <span>USD</span>
            </div>
          </div>
          <div class="address-area">
            <p>{{ $t("ADDRESS") }}</p>
            <div class="label">
              <div
                class="create link-over"
                v-on:click="QrCodeshowBEP20"
                v-if="isActive === '1'"
              >
                <img src="../assets/img/plus-icon-img.svg" alt="" />
                <p>{{ $t("CREATE ADDRESS") }}</p>
              </div>
              <div class="input-area" v-if="isActive === '2'">
                <input
                  type="text"
                  name=""
                  v-model="inputMoneyBEP"
                  :placeholder="$t('USD amount you wish to charge')"
                />
                <p>
                  <span
                    >{{
                      parseFloat(inputMoneyBEP / (1 - usdtFeeRate)).toFixed(4)
                    }} </span
                  >USDT
                </p>
              </div>
              <div class="img" v-if="isActive === '2'">
                <QRCodeVue3
                  :width="200"
                  :height="200"
                  :value="addressBEP"
                  :qrOptions="{
                    typeNumber: 8,
                    mode: 'Byte',
                    errorCorrectionLevel: 'H',
                  }"
                  :dotsOptions="{
                    type: 'classy',
                  }"
                />
              </div>
              <div class="input" v-if="isActive === '2'">
                <input type="text" :value="addressBEP" readonly />
                <span
                  class="link-over"
                  v-clipboard:copy="addressBEP"
                  v-clipboard:success="onCopySuccess"
                  v-clipboard:error="onCopyError"
                >
                  <img src="../assets/img/copy-icon2-img.svg" alt="" />
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- ./ -->
    <vue-final-modal
      name="modal"
      v-model="showModal"
      :resizable="true"
      :adaptive="true"
      :scrollable="true"
      width="100%"
      height="auto"
    >
      <div class="modal-box">
        <p>{{ $t("Would you like to create an address?") }}</p>
        <div class="btn">
          <button type="button" class="link-over" v-on:click="hide">
            {{ $t("YES") }}
          </button>
        </div>
      </div>
    </vue-final-modal>
  </div>
  <!-- /.contents -->
  <MainFooter />
</template>

<script>
import MainHeader from "@/components/MainHeader.vue";
import MainFooter from "@/components/MainFooter.vue";
import LoginUser from "@/js/loginUser.js";
import { i18n } from "@/i18n/i18n";
import Common from "../js/common";
import Deposit from "@/js/api/deposit";
import QRCodeVue3 from "qrcode-vue3";
import Account from "@/js/api/account";

export default {
  name: "DepositView",
  components: {
    MainHeader,
    MainFooter,
    QRCodeVue3,
  },
  data() {
    return {
      status: true,
      isActive: "1",
      address: "0xkjfajpfjopajfopafajpfjopajfopa",
      NumUSD: "",
      showModal: false,
      isTabActive: "1",
      addressTRC: "", //TRC存放地址
      addressBEP: "", //BEP存放地址
      userInfo: "",
      inputMoneyTRC: "", //输入金额
      inputMoneyBEP: "", //输入金额
      usdtFeeRate: "", //倍率
    };
  },
  created() {
    this.depositAddressesJudge();
    this.getFeeRate();
  },
  methods: {
    getFeeRate() {
      Deposit.getFeeRate().then((res) => {
        if (res) {
          this.usdtFeeRate = res.data.usdt;
          console.log(this.usdtFeeRate);
        }
      });
    },
    //判断又没有创建二维码
    depositAddressesJudge() {
      this.userInfo = LoginUser.getUserInfo();
      this.NumUSD = this.userInfo.ap;
      console.log(this.userInfo);
      if (this.userInfo.depositAddresses.TRX != undefined) {
        this.addressTRC = this.userInfo.depositAddresses.TRX;
        this.isActive = "2";
      }
      if (this.userInfo.depositAddresses.BNB != undefined) {
        this.addressBEP = this.userInfo.depositAddresses.BNB;
        this.isActive = "2";
      } else if (this.isTabActive == "2") {
        this.isActive = "1";
      }
      console.log(this.NumUSD);
    },
    isSelect: function (num) {
      this.isActive = num;
      this.isActive = "1";
    },
    isTabSelect: function (num) {
      this.isTabActive = num;
      this.isActive = "1";
      this.depositAddressesJudge();
    },
    onCopySuccess(e) {
      Common.success(e.text + i18n.global.t("Copy succeeded"));
    },
    onCopyError(e) {
      Common.error(e.text + i18n.global.t("Copy failed"));
    },
    QrCodeshowTrc20: function () {
      this.address = "USDT(TRC20)";
      this.$vfm.show("modal");
    },
    QrCodeshowBEP20: function () {
      this.address = "USDT(BEP20)";
      this.$vfm.show("modal");
    },
    hide: function () {
      this.$vfm.hide("modal");
      this.isActive = "2";
      this.createAddressApi();
    },
    createAddressApi() {
      Deposit.createAddress(this.address).then((response) => {
        console.log(response);
        switch (this.address) {
          case "USDT(TRC20)":
            this.addressTRC = response.data;
            Account.getLoginInfoAsync();
            break;
          case "USDT(BEP20)":
            this.addressBEP = response.data;
            Account.getLoginInfoAsync();
            break;
        }
      });
    },
  },
};
</script>
