<template>
  <div class="contents signin-page signup-page">
    <div class="box">
      <div class="inner">
        <h1>{{ $t("SIGN UP") }}</h1>
        <div class="ttl">
          <p>
            {{ $t("If you already have an account,") }}<br />
            {{ $t("please ") }}
            <router-link to="/signin" class="link-over">
              {{ $t("SIGN IN") }}.</router-link
            >
          </p>
        </div>
        <form @submit.prevent>
          <!-- 用户名 -->
          <div class="input-area">
            <!-- <p class="error-txt">validation</p> -->
            <input
              type="text"
              name=""
              :placeholder="$t('USER NAME')"
              v-model="username"
              required
            />
          </div>
          <!-- 邮箱 -->
          <div class="input-area email">
            <input
              type="email"
              name=""
              :placeholder="$t('EMAIL')"
              v-model="email"
              required
            />
            <span class="link-over" @click="sendCode">{{ $t("SEND") }}</span>
          </div>
          <!-- 验证码 -->
          <div class="input-area">
            <input
              type="text"
              name=""
              :placeholder="$t('VERIFICATION CODE')"
              v-model="VerificationCode"
              autocomplete=""
              required
            />
            <p class="attention-txt">
              {{
                $t(
                  "Please enter above the verification code we sent to your email address."
                )
              }}
            </p>
          </div>
          <!-- 密码 -->
          <div class="input-area">
            <input
              type="password"
              name=""
              :placeholder="$t('PASSWORD')"
              v-model="password"
              autocomplete=""
              required
            />
          </div>
          <!-- 再次输入密码 -->
          <div class="input-area">
            <input
              type="password"
              name=""
              :placeholder="$t('CONFIRM PASSWORD')"
              autocomplete=""
              v-model="confirmPassword"
              required
            />
          </div>
          <!-- 邀请码 -->
          <div class="input-area">
            <input
              type="text"
              name=""
              :placeholder="$t('INTRODUCER')"
              v-model="introducerPeople"
              required
              ref="introducerRef"
              disabled="true"
            />
          </div>
          <div class="agree-check">
            <input
              type="checkbox"
              v-model="AccountCheckBox"
              name=""
              id="agree"
            />
            <label for="agree" class="link-over">
              {{ $t("Multiple accounts are prohibited.") }}<br />
              {{ $t("Only one account per user is allowed.") }}<br />
              {{
                $t(
                  "If a violation is found, the account will be suspended without notice."
                )
              }}
            </label>
          </div>
          <div class="remember">
            <input type="checkbox" name="" id="check" />
            <label for="check">{{ $t("Remember the password") }}</label>
          </div>
          <div class="form-btn">
            <button v-on:click="submit" type="button" class="link-over">
              {{ $t("NEXT") }}
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
  <!-- /.contents -->
</template>

<script>
// import $ from "jquery";
import Account from "@/js/api/account";
import { i18n } from "@/i18n/i18n";
import Common from "@/js/common";
import { Notify } from "vant";
export default {
  name: "SignUp",
  components: {},
  data() {
    return {
      username: "",
      email: "",
      VerificationCode: "",
      password: "",
      confirmPassword: "",
      introducerPeople: "",
      AccountCheckBox: false,
    };
  },
  created() {
    this.introducerPeople = this.$route.query.Introducer;
  },
  mounted() {
    if (this.introducerPeople == null) {
      this.$refs.introducerRef.disabled = false;
    } else {
      this.$refs.introducerRef.style = "background-color:#eee !important";
    }
  },
  methods: {
    sendCode() {
      if (this.email) {
        var verify = /^\w[-\w.+]*@([A-Za-z0-9][-A-Za-z0-9]+\.)+[A-Za-z]{2,14}/;
        if (!verify.test(this.email)) {
          Notify({
            type: "danger",
            message: this.$t("Email format error"),
          });
          return;
        }
        this.loading = true;
        Account.PostSendRegisterCodeByEmail(this.email).then((res) => {
          this.loading = false;
          if (res) {
            console.log(res);
            Notify({
              type: "success",
              message: this.$t("Verification code has been sent to your email"),
            });
          }
        });
      } else {
        Notify({
          type: "danger",
          message: this.$t("Mailbox account cannot be empty!"),
        });
      }
    },
    // 获取token
    login() {
      let data = {
        username: this.username,
        password: this.password,
        grant_type: "Username_password",
        recaptchaResponse: "dummy",
      };
      Account.loginAsync(data).then((response) => {
        this.loggedIn(response.data);
      });
    },
    loggedIn(data) {
      Common.loggedIn(data.access_token, data.refresh_token);
    },

    // 点击注册
    submit() {
      if (!this.AccountCheckBox) {
        Common.error(i18n.global.t("Please select a protocol."));
        return;
      }
      let params = {
        Email: this.email,
        VerificationCode: this.VerificationCode,
        Username: this.username,
        Password: this.password,
        ConfirmPassword: this.confirmPassword,
        Introducer: this.introducerPeople,
      };
      Account.register(params)
        .then((response) => {
          // Common.loggedIn(
          //   response.data.access_token,
          //   response.data.refresh_token
          // );
          response;
          Common.success(i18n.global.t("Register succeed."));
          // 获取token
          this.login();
          let time = 0;
          let timer = setInterval(() => {
            if (time == 1) {
              clearTimeout(timer);
              this.$router.push({ path: "/" });
            }
            time = time + 1;
          }, 1000);
        })
        .finally(() => (this.loading = false));
      // this.$router.push({ path: "./home" });
    },
  },
};
</script>
