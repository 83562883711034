<template>
  <MainHeader :status="status" />
  <div class="contents item-page bg-img">
    <div class="fv">
      <div class="inner">
        <div class="list-main">
          <div class="txt">
            <h1>Let’s purchase favorite<br />NFT right now</h1>
            <div class="btn" v-if="status === false">
              <router-link to="/signin" class="link-over signin"
                >Sign IN</router-link
              >
              <router-link to="/signup" class="link-over signup"
                >Sign UP</router-link
              >
            </div>
          </div>
          <div class="img">
            <img src="../assets/img/item-main-img.svg" alt="" />
          </div>
        </div>
      </div>
    </div>
    <!-- ./fv -->
    <div class="list-box">
      <div class="inner">
        <h2>NFT</h2>
        <ul class="list">
          <li>
            <router-link to="/single" class="link-over">
              <div class="img">
                <img src="../assets/img/item-list1-img.svg" alt="" />
              </div>
              <div class="txt">
                <p>ITEM NAME</p>
                <p>$<span>1,000</span></p>
              </div>
            </router-link>
          </li>
          <li>
            <router-link to="/single" class="link-over">
              <div class="img">
                <img src="../assets/img/item-list2-img.svg" alt="" />
              </div>
              <div class="txt">
                <p>ITEM NAME</p>
                <p>$<span>3,000</span></p>
              </div>
            </router-link>
          </li>
          <li>
            <router-link to="/single" class="link-over">
              <div class="img">
                <img src="../assets/img/item-list3-img.svg" alt="" />
              </div>
              <div class="txt">
                <p>ITEM NAME</p>
                <p>$<span>5,000</span></p>
              </div>
            </router-link>
          </li>
        </ul>
      </div>
    </div>
    <!-- ./list-box -->
  </div>
  <!-- /.contents -->
  <MainFooter />
</template>

<script>
import MainHeader from "@/components/MainHeader.vue";
import MainFooter from "@/components/MainFooter.vue";

export default {
  name: "HomeView",
  components: {
    MainHeader,
    MainFooter,
  },
  data() {
    return {
      status: true,
    };
  },
};
</script>
