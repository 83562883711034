<template>
  <MainHeader :status="status" />
  <div class="contents market-page market-single-page">
    <div v-if="marketParamsData.BonusSettingId">
      <div>
        <div class="page-ttl">
          <div class="inner">
            <h1>
              ${{
                marketParamsData.BonusSettingId == "plage1"
                  ? "1000"
                  : marketParamsData.BonusSettingId == "plage2"
                  ? "5000"
                  : marketParamsData.BonusSettingId == "plage3"
                  ? "10000"
                  : marketParamsData.BonusSettingId == "plage4"
                  ? "50000"
                  : ""
              }}
              MART
            </h1>
          </div>
        </div>
        <div class="search-area">
          <div class="inner">
            <div class="box">
              <div class="input-area flex">
                <input
                  v-model="marketParamsData.fromPrice"
                  type="text"
                  name=""
                  :placeholder="$t('Price range (lower limit)')"
                />
                <div class="icon">
                  <img src="../assets/img/search-icon-img.png" alt="" />
                </div>
                <input
                  v-model="marketParamsData.toPrice"
                  type="text"
                  name=""
                  :placeholder="$t('Price range (upper limit)')"
                />
              </div>
              <div class="input-area">
                <input
                  v-model="marketParamsData.userName"
                  type="text"
                  name=""
                  :placeholder="$t('Enter the seller')"
                />
              </div>
              <div class="btn">
                <button
                  type="button"
                  class="link-over"
                  @click="getListMaretNft()"
                >
                  {{ $t("SEARCH") }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="buy-list list-box">
        <div class="inner">
          <ul class="list">
            <li v-for="(item, i) in marketNftData" :key="i">
              <div class="img">
                <img :src="item.url" alt="" />
              </div>
              <div class="desc">
                <p>{{ $t("SELLER：") + item.userName }}</p>
                <div class="price">${{ item.price }}</div>
              </div>
              <div class="btn">
                <button
                  type="button"
                  class="link-over"
                  v-on:click="showBuy(item)"
                >
                  {{ $t("Buy") }}
                </button>
              </div>
            </li>
          </ul>
        </div>
        <div v-if="total.marketTotal > marketParamsData.take" class="page-nav">
          <van-pagination
            v-model="marketParamsData.index"
            :total-items="total.marketTotal"
            :show-page-size="3"
            :items-per-page="marketParamsData.take"
            @change="getListMaretNft()"
            force-ellipses
          >
            <template #prev-text>
              {{ $t("PREVIOUS") }}
            </template>
            <template #next-text>
              {{ $t("NEXT") }}
            </template>
          </van-pagination>
        </div>
      </div>
    </div>
    <div class="page-ttl">
      <div class="inner">
        <div class="ttl">{{ $t("CHUCHU MART") }}</div>
      </div>
    </div>
    <div class="price-box">
      <div class="inner">
        <ul class="price-list">
          <li>
            <div class="link-over" @click="getListMaretNft('plage1')">
              <div class="img">
                <img src="../assets/img/item-list1-img.png" alt="" />
              </div>
              <div class="txt">
                <p>$1,000</p>
              </div>
              <div class="icon">
                <img src="../assets/img/link-icon-1000-img.png" alt="" />
              </div>
            </div>
          </li>
          <li>
            <div class="link-over" @click="getListMaretNft('plage2')">
              <div class="img">
                <img src="../assets/img/item-list2-img.png" alt="" />
              </div>
              <div class="txt">
                <p>$5,000</p>
              </div>
              <div class="icon">
                <img src="../assets/img/link-icon-5000-img.png" alt="" />
              </div>
            </div>
          </li>
          <li>
            <div class="link-over" @click="getListMaretNft('plage3')">
              <div class="img">
                <img src="../assets/img/item-list3-img.png" alt="" />
              </div>
              <div class="txt">
                <p>$10,000</p>
              </div>
              <div class="icon">
                <img src="../assets/img/link-icon-10000-img.png" alt="" />
              </div>
            </div>
          </li>
          <li>
            <div class="link-over" @click="getListMaretNft('plage4')">
              <div class="img">
                <img src="../assets/img/item-list4-img.png" alt="" />
              </div>
              <div class="txt">
                <p>$50,000</p>
              </div>
              <div class="icon">
                <img src="../assets/img/link-icon-50000-img.png" alt="" />
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <!-- ./list-box -->
    <div class="page-ttl">
      <div class="inner">
        <h2>{{ $t("MY NFT") }}</h2>
      </div>
    </div>
    <div class="tab-box">
      <div class="inner">
        <div class="scroll">
          <span
            class="link-over"
            v-on:click="isSelect('Plage1')"
            v-bind:class="{
              active: isActive === 'Plage1',
            }"
            >$1,000</span
          >
          <span
            class="link-over"
            v-on:click="isSelect('Plage2')"
            v-bind:class="{
              active: isActive === 'Plage2',
            }"
            >$5,000</span
          >
          <span
            class="link-over"
            v-on:click="isSelect('Plage3')"
            v-bind:class="{
              active: isActive === 'Plage3',
            }"
            >$10,000</span
          >
          <span
            class="link-over"
            v-on:click="isSelect('Plage4')"
            v-bind:class="{
              active: isActive === 'Plage4',
            }"
            >$50,000</span
          >
        </div>
      </div>
    </div>
    <div class="nft-list list-box">
      <div class="inner">
        <div>
          <div
            v-if="loading.myNftLoading"
            class="loading_center"
            style="text-align: center"
          >
            <van-loading type="spinner" />
          </div>
          <ul class="list">
            <li
              v-for="(plage, i) in myNftData"
              :key="i"
              v-bind:class="{
                active: plage.transactionStatus != null || plage.isStaking,
              }"
            >
              <div class="img">
                <img :src="plage.url" alt="" />
              </div>
              <div
                v-if="
                  !plage.isStaking && plage.transactionStatus != 'Completed'
                "
              >
                <div
                  class="notice"
                  v-show="plage.transactionStatus == 'Pending'"
                >
                  {{ $t("ON DISPLAY") }}
                </div>
                <div class="btn">
                  <div v-if="plage.transactionStatus != 'Pending'">
                    <button
                      type="button"
                      class="link-over"
                      v-on:click="showPut(plage.id, plage.url)"
                    >
                      {{ $t("Put up") }}
                    </button>
                  </div>
                  <div v-else>
                    <button
                      type="button"
                      class="link-over"
                      v-on:click="showCancel(plage.nftOrderId, plage.url)"
                    >
                      {{ $t("Cancel") }}
                    </button>
                  </div>
                </div>
              </div>
              <div v-else class="stake-icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  width="194"
                  height="194"
                  viewBox="0 0 194 194"
                >
                  <defs>
                    <clipPath id="clip-path">
                      <rect
                        id="長方形_439"
                        data-name="長方形 439"
                        width="194"
                        height="194"
                        transform="translate(1187.406 635.422)"
                        fill="#fff"
                        stroke="#707070"
                        stroke-width="1"
                      />
                    </clipPath>
                  </defs>
                  <g
                    id="マスクグループ_25"
                    data-name="マスクグループ 25"
                    transform="translate(-1187.406 -635.422)"
                    clip-path="url(#clip-path)"
                  >
                    <g
                      id="_饑1"
                      data-name="ﾑ饑1"
                      transform="translate(1198.615 635.422)"
                    >
                      <path
                        id="パス_29"
                        data-name="パス 29"
                        d="M85.606,81.683h.366c7.814,0,14.15-4.267,14.15-9.53V9.53c0-5.264-6.334-9.53-14.15-9.53h-.366c-7.814,0-14.15,4.267-14.15,9.53V72.155c0,5.264,6.334,9.53,14.15,9.53Z"
                        fill="#fff"
                      />
                      <path
                        id="パス_30"
                        data-name="パス 30"
                        d="M134.707,37.765a11.488,11.488,0,0,0-18.053,9.426v.452a11.488,11.488,0,0,0,4.892,9.406,62.443,62.443,0,1,1-71.511,0,11.488,11.488,0,0,0,4.892-9.406v-.452a11.488,11.488,0,0,0-18.053-9.426,85.791,85.791,0,1,0,97.832,0Z"
                        fill="#fff"
                      />
                    </g>
                  </g>
                </svg>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div v-if="total.myNftTotal > 8" class="page-nav">
        <van-pagination
          v-model="myNftParamsData.index"
          :total-items="total.myNftTotal"
          :show-page-size="3"
          :items-per-page="myNftParamsData.take"
          @change="getMyNftList"
          force-ellipses
        >
          <template #prev-text>
            {{ $t("PREVIOUS") }}
          </template>
          <template #next-text>
            {{ $t("NEXT") }}
          </template>
        </van-pagination>
      </div>
    </div>
    <vue-final-modal
      name="buyModal"
      v-model="buyModal"
      :resizable="true"
      :adaptive="true"
      :scrollable="true"
      width="100%"
      height="auto"
    >
      <div class="modal-box buy-modal">
        <div class="detail">
          <div class="img">
            <img :src="marketBuyNftParams.url" alt="" />
          </div>
          <div class="txt">
            <div class="price">
              {{ marketBuyNftParams.price }}<span><b>USD</b><br />(CCT)</span>
            </div>
            <div class="tab-area">
              <span
                class="tab link-over"
                v-on:click="isModalSelect('1')"
                v-bind:class="{
                  active: isModalActive === '1',
                }"
                >USD</span
              >
              <span
                class="tab link-over"
                v-on:click="isModalSelect('2')"
                v-bind:class="{
                  active: isModalActive === '2',
                }"
                >CCT</span
              >
            </div>
            <div class="balance" v-if="isModalActive === '1'">
              {{ $t("BALANCE") }} {{ this.ap }} USD
            </div>
            <div class="balance" v-if="isModalActive === '2'">
              {{ $t("BALANCE") }} {{ this.rp }} CCT
            </div>
            <div class="input-area" v-if="isModalActive === '1'">
              <input
                type="text"
                name=""
                :placeholder="$t('Amount')"
                v-model="buyInput.usdInput"
              />
              <span>USD</span>
            </div>
            <div class="input-area" v-if="isModalActive === '2'">
              <input
                type="text"
                name=""
                :placeholder="$t('Amount')"
                v-model="buyInput.cctInput"
              />
              <span>CCT</span>
            </div>
          </div>
        </div>
        <div class="ttl">{{ $t("Are you sure you want to buy?") }}</div>
        <div class="btn">
          <button type="button" class="link-over not-btn" v-on:click="hideBuy">
            {{ $t("NO") }}
          </button>
          <button
            type="button"
            class="link-over buy-btn"
            v-bind:disabled="
              !(
                Number(buyInput.usdInput) + Number(buyInput.cctInput) ==
                  marketBuyNftParams.price &&
                Number(buyInput.usdInput) >= 0 &&
                Number(buyInput.cctInput) >= 0
              )
            "
            v-on:click="doneModal"
          >
            {{ $t("Buy now") }}
          </button>
        </div>
      </div>
    </vue-final-modal>
    <vue-final-modal
      name="comfirmModal"
      v-model="comfirmModal"
      :resizable="true"
      :adaptive="true"
      :scrollable="true"
      width="100%"
      height="auto"
    >
      <div class="modal-box buy-modal">
        <div class="detail">
          <div class="img">
            <img :src="marketBuyNftParams.url" alt="" />
          </div>
          <div class="txt">
            <div class="price">${{ marketBuyNftParams.price }}</div>
            <div class="balance center">{{ $t("BALANCE") }} ${{ ap }}</div>
            <div class="icon">
              <img src="../assets/img/item-download-img.svg" alt="" />
            </div>
          </div>
        </div>
        <div class="ttl">{{ $t("Are you sure you want to buy?") }}</div>
        <div class="btn">
          <button type="button" class="link-over not-btn" v-on:click="hideBuy">
            {{ $t("NO") }}
          </button>
          <button type="button" class="link-over buy-btn" v-on:click="complete">
            {{ $t("Buy now") }}
          </button>
        </div>
      </div>
    </vue-final-modal>
    <vue-final-modal
      name="cancelModal"
      v-model="cancelModal"
      :resizable="true"
      :adaptive="true"
      :scrollable="true"
      width="100%"
      height="auto"
    >
      <div class="modal-box send-modal">
        <p>{{ $t("Are you sure you want to cancel") }}?</p>
        <div class="img">
          <img :src="oneNftImage" alt="" />
        </div>
        <div class="btn">
          <button
            type="button"
            class="link-over not-btn"
            v-on:click="cancelHideCancel"
          >
            {{ $t("Cancel") }}
          </button>
          <button
            type="button"
            class="link-over done-btn"
            v-on:click="hideCancel"
          >
            {{ $t("YES") }}
          </button>
        </div>
      </div>
    </vue-final-modal>
    <vue-final-modal
      name="putModal"
      v-model="putModal"
      :resizable="true"
      :adaptive="true"
      :scrollable="true"
      width="100%"
      height="auto"
    >
      <div class="modal-box put-modal">
        <div class="detail">
          <div class="img">
            <img :src="oneNftImage" alt="" />
          </div>
          <div class="txt">
            <div class="ttl">
              {{ $t("How much do you want to sell the item for?") }}
            </div>
            <div class="input-area">
              <input
                type="number"
                name=""
                :placeholder="$t('Amount')"
                v-model="amountValue"
              />
              <span>USD</span>
            </div>
          </div>
        </div>
        <div class="btn">
          <button type="button" class="link-over not-btn" v-on:click="hidePut">
            {{ $t("Cancel") }}
          </button>
          <button
            type="button"
            class="link-over put-btn"
            v-on:click="cellModal"
            :disabled="amountValue == ''"
          >
            {{ $t("Put up") }}
          </button>
        </div>
      </div>
    </vue-final-modal>
    <vue-final-modal
      name="completeModal"
      v-model="completeModal"
      :resizable="true"
      :adaptive="true"
      :scrollable="true"
      width="100%"
      height="auto"
    >
      <div class="modal-box complete-modal">
        <div class="txt">{{ $t("Congratulation!!") }}</div>
        <div class="img">
          <img :src="oneNftImage" alt="" />
        </div>
        <div class="btn">
          <router-link
            to="./wallet"
            class="link-over"
            v-on:click="hideComplete"
            >{{ $t("MY NFT") }}</router-link
          >
          <div class="link-over div_cancel" v-on:click="buyNftHide">
            {{ $t("Cancel") }}
          </div>
        </div>
        <div class="balloons">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </vue-final-modal>
  </div>
  <!-- /.contents -->
  <MainFooter />
</template>

<script>
import MainHeader from "@/components/MainHeader.vue";
import MainFooter from "@/components/MainFooter.vue";
import Plate from "@/js/api/plate";
import LoginUser from "@/js/loginUser.js";
import Common from "../js/common";
import { i18n } from "../i18n/i18n";
export default {
  name: "Market1000View",
  components: {
    MainHeader,
    MainFooter,
  },
  data() {
    return {
      ap: 0,
      rp: 0,
      status: true,
      buyModal: false,
      comfirmModal: false,
      putModal: false,
      cancelModal: false,
      completeModal: false,
      isStaking: true,
      isPutup: true,
      amountValue: "",
      isActive: "Plage1",
      isModalActive: "1",
      inputUsd: "",
      inputCct: "",
      myNftData: [],
      marketNftData: [],
      oneNftID: null,
      oneNftOrderId: null,
      oneNftImage: "",
      loading: { myNftLoading: false },
      marketParamsData: {
        fromPrice: undefined,
        toPrice: undefined,
        BonusSettingId: null,
        index: 1,
        take: 4,
        userName: undefined,
      },
      myNftParamsData: {
        index: 1,
        take: 8,
      },
      total: {
        marketTotal: 0,
        myNftTotal: 0,
      },
      marketBuyNftParams: {
        id: 0,
        url: "",
        price: 0,
      },
      buyInput: {
        usdInput: null,
        cctInput: null,
      },
    };
  },
  created() {
    this.getMyNftList();
    this.ap = LoginUser.getap();
    this.rp = LoginUser.getRp();
  },
  methods: {
    // 获取数据
    getMyNftList() {
      this.loading.myNftLoading = true;
      let params = {
        BonusSettingId: this.isActive,
        index: Number(this.myNftParamsData.index) - 1,
        take: this.myNftParamsData.take,
      };
      Plate.ListMyNft(params)
        .then((response) => {
          this.myNftData = response.data.detail;
          this.total.myNftTotal = response.data.total;
        })
        .finally(() => (this.loading.myNftLoading = false));
    },
    getListMaretNft(BonusSettingId) {
      if (BonusSettingId) {
        this.marketParamsData.BonusSettingId = BonusSettingId;
      } else if (!this.marketParamsData.BonusSettingId) {
        return;
      }
      Plate.getListMarketNft(this.marketParamsData).then((response) => {
        this.marketNftData = response.data.detail;
        this.total.marketTotal = response.data.total;
      });
    },
    isSelect: function (num) {
      this.isActive = num;
      this.getMyNftList();
    },
    isModalSelect: function (num) {
      this.isModalActive = num;
    },
    showBuy: function (item) {
      this.marketBuyNftParams.id = item.id;
      this.marketBuyNftParams.url = item.url;
      this.marketBuyNftParams.price = item.price;
      this.$vfm.show("buyModal");
    },
    hideBuy: function () {
      this.buyInput.usdInput = null;
      this.buyInput.cctInput = null;
      this.$vfm.hide("buyModal");
      this.$vfm.hide("comfirmModal");
    },
    //Put Up 购买弹框显示
    showPut: function (id, image) {
      this.oneNftID = id;
      this.oneNftImage = image;
      this.$vfm.show("putModal");
    },
    hidePut: function () {
      this.amountValue = null;
      this.$vfm.hide("putModal");
    },
    // 出售中状态点击cancel
    showCancel: function (nftOrderId, image) {
      this.oneNftOrderId = nftOrderId;
      this.oneNftImage = image;
      this.$vfm.show("cancelModal");
    },
    hideCancel: function () {
      Plate.PostCancelPutOnNft(this.oneNftOrderId).then(() => {
        this.$vfm.hide("cancelModal");
        this.getMyNftList();
        this.getListMaretNft();
      });
    },
    cancelHideCancel: function () {
      this.$vfm.hide("cancelModal");
    },
    hideComplete: function () {
      this.$vfm.hide("completeModal");
    },
    cellModal: function () {
      Plate.PostPutOnNft(this.oneNftID, this.amountValue).then(() => {
        this.$vfm.hide("putModal");
        this.$vfm.show("completeModal");
        this.getMyNftList();
        this.getListMaretNft();
      });
      this.amountValue = null;
    },
    doneModal: function () {
      if (
        Number(this.buyInput.usdInput) > Number(this.ap) ||
        Number(this.buyInput.cctInput) > Number(this.rp)
      ) {
        Common.error(i18n.global.t("INSUFFICIENT BALANCE"));
        return;
      }
      this.$vfm.hide("buyModal");
      this.$vfm.show("comfirmModal");
    },
    buyNftHide: function () {
      this.$vfm.hide("completeModal");
      console.log(111);
    },
    complete: function () {
      const params = {
        DeductionRP:
          this.buyInput.cctInput == null ? 0 : this.buyInput.cctInput,
        NftOrderId: this.marketBuyNftParams.id,
      };
      Plate.PostBuyNft(params).then(() => {
        this.$vfm.hide("comfirmModal");
        this.$vfm.show("completeModal");
        this.oneNftImage = this.marketBuyNftParams.url;
        this.getMyNftList();
        this.getListMaretNft();
      });
    },
  },
};
</script>
<style lang="less" scoped>
:deep(.van-pagination__item--active) {
  color: red;
}
.div_cancel {
  display: inline-block;
  width: 160px;
  padding: 10px 10px;
  border-radius: 30px;
  font-weight: 600;
  letter-spacing: 1px;
  margin: 0 10px;
  background-color: #fff;
  color: #7ed7ff;
  margin-top: 5px;
}
</style>
