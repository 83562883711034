<template>
  <MainHeader :status="status" />
  <div class="contents history-page bg-back">
    <div class="page-ttl">
      <div class="inner">
        <h1>{{ $t("HISTORY") }}</h1>
        <div class="link">
          <router-link to="/transactionhistory" class="link-over active">{{
            $t("TRANSACTION")
          }}</router-link>
          <router-link to="/itemhistory" class="link-over">{{
            $t("NFT")
          }}</router-link>
        </div>
      </div>
    </div>
    <div class="transaction-box">
      <div class="inner">
        <div class="table-area">
          <div
            class="change-box"
            v-bind:class="{
              active: isActive === '1',
            }"
          >
            <h2>{{ $t("PROFIT HISTORY") }}</h2>
            <div class="profit-tab">
              <div class="scroll">
                <span
                  class="link-over"
                  v-on:click="isProfitSelect('1')"
                  v-bind:class="{
                    active: isProfitActive === '1',
                  }"
                  >{{ $t("Show All") }}</span
                >
                <span
                  class="link-over"
                  v-on:click="isProfitSelect('2')"
                  v-bind:class="{
                    active: isProfitActive === '2',
                  }"
                  >{{ $t("Staking Reward") }}</span
                >
                <span
                  class="link-over"
                  v-on:click="isProfitSelect('3')"
                  v-bind:class="{
                    active: isProfitActive === '3',
                  }"
                  >{{ $t("Unilevel Bonus") }}</span
                >
              </div>
            </div>
            <table class="table">
              <tr>
                <th>{{ $t("DATE") }}</th>
                <th>{{ $t("TYPE") }}</th>
                <th>{{ $t("POINT") }}</th>
                <th>
                  {{ $t("AMOUNT") }}<br />
                  {{ $t("BALANCE") }}
                </th>
              </tr>
              <template v-if="tableList.length > 0">
                <tr v-for="(item, index) in tableList" :key="index">
                  <td>{{ item.createdTime }}</td>
                  <td>{{ $t(item.type) }}</td>
                  <td>{{ item.assetName }}</td>

                  <td class="balance" style="text-align: right">
                    {{ item.pointsText }}<br />
                    {{ item.after }}
                  </td>
                </tr>
              </template>
              <tr v-else>
                <td colspan="99">{{ $t("NO DATA") }}</td>
              </tr>
            </table>
          </div>
          <div
            class="change-box"
            v-bind:class="{
              active: isActive === '2',
            }"
          >
            <h2>{{ $t("TRANSFER HISTORY") }}</h2>
            <table class="table">
              <tr>
                <th>{{ $t("DATE") }}</th>
                <th>{{ $t("TYPE") }}</th>
                <th>{{ $t("POINT") }}</th>
                <th>
                  {{ $t("AMOUNT") }}<br />
                  {{ $t("BALANCE") }}
                </th>
              </tr>
              <template v-if="tableList.length > 0">
                <tr v-for="(item, index) in tableList" :key="index">
                  <td>{{ item.createdTime }}</td>
                  <td>{{ $t(item.type) }}</td>
                  <td>{{ item.assetName }}</td>

                  <td class="balance" style="text-align: right">
                    {{ item.pointsText }}<br />
                    {{ item.after }}
                  </td>
                </tr>
              </template>
              <tr v-else>
                <td colspan="99">{{ $t("NO DATA") }}</td>
              </tr>
            </table>
          </div>
          <div
            class="change-box"
            v-bind:class="{
              active: isActive === '3',
            }"
          >
            <h2>TRANSFAR ITEM HISTORY</h2>
            <table class="table">
              <tr>
                <th>{{ $t("DATE") }}</th>
                <th>ITEM</th>
                <th>PRICE</th>
                <th></th>
              </tr>
              <tr>
                <td>2021.07.01 14:15:36</td>
                <td>
                  <div class="img">
                    <img src="../assets/img/item-list1-img.svg" alt="" />
                  </div>
                </td>
                <td class="price">10,000</td>
                <td class="btn">
                  <button type="button" class="link-over cancel">Cancel</button>
                </td>
              </tr>
              <tr>
                <td>2021.07.01 14:15:36</td>
                <td>
                  <div class="img">
                    <img src="../assets/img/item-list1-img.svg" alt="" />
                  </div>
                </td>
                <td class="price">10,000</td>
                <td class="btn">
                  <button type="button" class="link-over complete">
                    Complete
                  </button>
                </td>
              </tr>
            </table>
          </div>
          <div
            class="change-box"
            v-bind:class="{
              active: isActive === '4',
            }"
          >
            <h2>{{ $t("DEPOSIT HISTORY") }}</h2>
            <table class="table">
              <tr>
                <th>{{ $t("DATE") }}</th>
                <th>{{ $t("TYPE") }}</th>
                <th>{{ $t("AMOUNT") }}</th>
                <th>{{ $t("BALANCE") }}</th>
              </tr>
              <template v-if="tableList.length > 0">
                <tr v-for="(item, index) in tableList" :key="index">
                  <td>{{ item.createdTime }}</td>
                  <td>{{ $t(item.type) }}</td>
                  <td style="text-align: right">{{ item.pointsText }}</td>
                  <td class="balance" style="text-align: right">
                    {{ item.after }}
                  </td>
                </tr>
              </template>
              <tr v-else>
                <td colspan="99">{{ $t("NO DATA") }}</td>
              </tr>
            </table>
          </div>
          <div
            class="change-box"
            v-bind:class="{
              active: isActive === '5',
            }"
          >
            <h2>{{ $t("WITHDRAW HISTORY") }}</h2>
            <table class="table">
              <tr>
                <th>{{ $t("DATE") }}</th>
                <th>{{ $t("TYPE") }}</th>
                <th>
                  {{ $t("AMOUNT") }}<br />
                  {{ $t("BALANCE") }}
                </th>
              </tr>
              <template v-if="tableList.length > 0">
                <tr v-for="(item, index) in tableList" :key="index">
                  <td>{{ item.createdTime }}</td>
                  <td>{{ $t(item.type) }}</td>

                  <td class="balance" style="text-align: right">
                    {{ item.pointsText }}<br />
                    {{ item.after }}
                  </td>
                </tr>
              </template>
              <tr v-else>
                <td colspan="99">{{ $t("NO DATA") }}</td>
              </tr>
            </table>
          </div>
          <div class="page-nav">
            <van-pagination
              v-if="total > 4"
              v-model="currentPage"
              :total-items="total"
              :show-page-size="3"
              :items-per-page="4"
              @change="onPage"
              force-ellipses
            >
              <template #prev-text>
                {{ $t("PREVIOUS") }}
              </template>
              <template #next-text>
                {{ $t("NEXT") }}
              </template>
            </van-pagination>
          </div>
        </div>
        <div class="tab-area">
          <p>{{ $t("HISTORY MENU") }}</p>
          <div class="scroll">
            <div class="tab">
              <span
                class="link-over link-over"
                v-on:click="isSelect('1')"
                v-bind:class="{
                  active: isActive === '1',
                }"
                >{{ $t("PROFIT") }}</span
              >
              <span
                class="link-over link-over"
                v-on:click="isSelect('2')"
                v-bind:class="{
                  active: isActive === '2',
                }"
                >{{ $t("TRANSFER CCT") }}</span
              >
              <!-- <span
                class="link-over link-over"
                v-on:click="isSelect('3')"
                v-bind:class="{
                  active: isActive === '3',
                }"
                >TRANSFER ITEM</span
              > -->
              <span
                class="link-over link-over"
                v-on:click="isSelect('4')"
                v-bind:class="{
                  active: isActive === '4',
                }"
                >{{ $t("Deposit") }}</span
              >
              <!-- <span
                class="link-over link-over"
                v-on:click="isSelect('5')"
                v-bind:class="{
                  active: isActive === '5',
                }"
                >{{ $t("Withdrawal") }}</span
              > -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- ./transaction-box -->
  </div>
  <!-- /.contents -->
  <MainFooter />
</template>

<script>
import MainHeader from "@/components/MainHeader.vue";
import MainFooter from "@/components/MainFooter.vue";
import Asset from "@/js/api/asset.js";

export default {
  name: "TransactionHistory",
  components: {
    MainHeader,
    MainFooter,
  },
  data() {
    return {
      status: true,
      isActive: "1",
      currentPage: 1,
      total: 0,
      isProfitActive: null,
      tableList: [],
    };
  },
  created() {
    this.isSelect("1");
  },
  methods: {
    isSelect: function (num) {
      this.currentPage = 1;
      this.isActive = num;
      if (this.isActive === "5") {
        this.getWithdrawals();
      } else {
        this.getHistories();
      }
    },
    isProfitSelect(isProfitActive) {
      this.isProfitActive = isProfitActive;
      this.getHistories();
    },
    getHistories: function () {
      let data = { index: this.currentPage - 1, take: 4 };
      if (this.isActive === "1") {
        data.range = "Profit2";
      } else if (this.isActive === "2") {
        data.historyType = "PointToUser";
        data.range = "Send";
      } else if (this.isActive === "4") {
        data.assetType = "AP";
        data.historyType = "Deposit";
        data.range = "Deposit";
      }
      if (this.isProfitActive == null || this.isProfitActive == 1) {
        data.historyType = null;
      } else if (this.isProfitActive == 2) {
        data.historyType = "PledgeStaticBonus";
      } else if (this.isProfitActive == 3) {
        data.historyType = "PledgeDynamicBonus";
      }
      Asset.getHistories(data).then((res) => {
        if (res) {
          this.tableList = res.data.detail;
          this.total = res.data.total;
        }
      });
    },
    getWithdrawals() {
      let data = {
        index: this.currentPage - 1,
        take: 4,
      };
      Asset.getWithdrawals(data).then((res) => {
        if (res) {
          this.tableList = res.data.detail;
          this.total = res.data.total;
        }
      });
    },
    onPage(val) {
      this.currentPage = val;
      if (this.isActive === "5") {
        this.getWithdrawals();
      } else {
        this.getHistories();
      }
    },
  },
};
</script>
