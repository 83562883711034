import Fetch from "@/js/api/fetch";

const Deposit = {
  createAddress: (data) => Fetch.post(`/deposit/createAddress/${data}`),
  getHistory: (data) =>
    Fetch.get("/deposit/history", {
      params: data,
    }),

  getPrices: () => Fetch.get("/deposit/prices"),

  getFeeRate: () => Fetch.get("/deposit/feeRate"),
};

export default Deposit;
