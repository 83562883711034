import Axios from "axios";
import Common from "../common";
import Definitions from "../definitions";
// import Token from "./token";

const Fetch = Axios.create();

Fetch.defaults.baseURL = Common.backendUrl;

// Add a request interceptor
Fetch.interceptors.request.use(
  (config) => {
    // Do something before request is sent
    config.headers["Authorization"] =
      "Bearer " + localStorage.getItem(Definitions.StorageKeys.accessToken);
    let url = config.url.startsWith("/") ? config.url : `/${config.url}`;
    url = url.indexOf("?") !== -1 ? `${url}&` : `${url}?`;
    config.url = `${url}culture=${localStorage.getItem(
      Definitions.StorageKeys.language
    )}`;
    return config;
  },
  (error) => {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
Fetch.interceptors.response.use(
  (response) => {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  async (error) => {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    const refreshToken = localStorage.getItem(
      Definitions.StorageKeys.refreshToken
    );
    switch (error.response.status) {
      case 401:
        if (refreshToken) {
          // const refreshResponse = await Token.postAsync({
          //   grant_type: "refresh_token",
          //   refresh_token: refreshToken,
          // });
          // if (refreshResponse && refreshResponse.status === 200) {
          //   Common.loggedIn(
          //     refreshResponse.data.access_token,
          //     refreshResponse.data.refresh_token
          //   ); //retry
          //   const originalRequest = error.config;
          //   originalRequest._retry = true;
          //   return Fetch(originalRequest);
          // } else {
          //   Common.loggedOut();
          // }
          Common.loggedOut();
        } else {
          Common.loggedOut();
        }
        return;

      case 403:
        Common.loggedOut();
        return;

      default:
        Common.handleError(error);
        break;
    }
    if (Common.isDevelopment()) {
      console.dir(error);
    }
    return Promise.reject(error);
  }
);

export default Fetch;
