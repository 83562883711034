<template>
  <MainHeader :status="status" />
  <div class="contents history-page bg-back">
    <div class="page-ttl">
      <div class="inner">
        <h1>{{ $t("HISTORY") }}</h1>
        <div class="link">
          <router-link to="/transactionhistory" class="link-over">{{
            $t("TRANSACTION")
          }}</router-link>
          <router-link to="/itemhistory" class="link-over active">{{
            $t("NFT")
          }}</router-link>
        </div>
      </div>
    </div>
    <div class="list-box">
      <div class="inner">
        <ul class="list">
          <li v-for="(item, i) in itemList" :key="i">
            <router-link to="/single" class="link-over">
              <div class="img">
                <img :src="item.url" alt="" />
              </div>
              <div class="txt">
                <p class="date">{{ item.createdTime }}</p>
                <!-- <p>{{ item.name }}</p> -->
                <p>
                  $<span>{{ item.min }}</span>
                </p>
              </div>
            </router-link>
          </li>
        </ul>
      </div>
    </div>
    <!-- ./list-box -->
  </div>
  <!-- /.contents -->
  <MainFooter />
</template>

<script>
import MainHeader from "@/components/MainHeader.vue";
import MainFooter from "@/components/MainFooter.vue";
import Plate from "@/js/api/plate";
export default {
  name: "ItemHistory",
  components: {
    MainHeader,
    MainFooter,
  },
  data() {
    return {
      status: true,
      itemList: [],
    };
  },
  created() {
    this.createList();
  },
  methods: {
    createList() {
      let params = {
        index: 0,
        take: 10000,
      };
      Plate.ListMyNft(params)
        .then((response) => {
          // console.log(response);
          this.itemList = response.data.detail;
        })
        .finally(() => (this.loading = false));
    },
  },
};
</script>
