const Definitions = {
  StorageKeys: {
    language: "language",
    accessToken: "accessToken",
    refreshToken: "refreshToken",
    userInfo: "userInfo",
  },
};

export default Definitions;
