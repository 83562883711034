<template>
  <MainHeader :status="status" />
  <div class="contents staking-page bg-back">
    <div class="page-ttl">
      <div class="inner">
        <div class="ttl">
          <h1>{{ $t("STAKING") }}</h1>
          <div class="tab">
            <span
              v-for="(item, i) in BonusSettingIdList"
              :key="i"
              class="link-over"
              v-on:click="isSelect(Number(i) + 1 + '', item.id)"
              v-bind:class="{
                active: isActive === Number(i) + 1 + '',
              }"
              >{{ item.min }}</span
            >
          </div>
        </div>
      </div>
    </div>
    <div class="list-box">
      <div class="inner">
        <div>
          <ul class="list">
            <li
              v-bind:class="{
                active: isStaking === true,
              }"
              v-for="(item, i) in itemList"
              :key="i"
            >
              <div class="action-wrap link-over" v-on:click="showBuy(item)">
                <div class="img">
                  <img :src="item.url" alt="" />
                </div>
                <div class="txt">
                  <!-- <p>ITEM NAME</p> -->
                  <p>
                    $<span>{{ item.min }}</span>
                  </p>
                </div>
              </div>
              <div
                class="active-flag"
                v-if="item.isStaking || item.transactionStatus != null"
              >
                <div
                  v-if="item.isStaking || item.transactionStatus == 'Completed'"
                >
                  <div class="icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink"
                      width="194"
                      height="194"
                      viewBox="0 0 194 194"
                    >
                      <defs>
                        <clipPath id="clip-path">
                          <rect
                            id="長方形_439"
                            data-name="長方形 439"
                            width="194"
                            height="194"
                            transform="translate(1187.406 635.422)"
                            fill="#fff"
                            stroke="#707070"
                            stroke-width="1"
                          />
                        </clipPath>
                      </defs>
                      <g
                        id="マスクグループ_25"
                        data-name="マスクグループ 25"
                        transform="translate(-1187.406 -635.422)"
                        clip-path="url(#clip-path)"
                      >
                        <g
                          id="_饑1"
                          data-name="ﾑ饑1"
                          transform="translate(1198.615 635.422)"
                        >
                          <path
                            id="パス_29"
                            data-name="パス 29"
                            d="M85.606,81.683h.366c7.814,0,14.15-4.267,14.15-9.53V9.53c0-5.264-6.334-9.53-14.15-9.53h-.366c-7.814,0-14.15,4.267-14.15,9.53V72.155c0,5.264,6.334,9.53,14.15,9.53Z"
                            fill="#fff"
                          />
                          <path
                            id="パス_30"
                            data-name="パス 30"
                            d="M134.707,37.765a11.488,11.488,0,0,0-18.053,9.426v.452a11.488,11.488,0,0,0,4.892,9.406,62.443,62.443,0,1,1-71.511,0,11.488,11.488,0,0,0,4.892-9.406v-.452a11.488,11.488,0,0,0-18.053-9.426,85.791,85.791,0,1,0,97.832,0Z"
                            fill="#fff"
                          />
                        </g>
                      </g>
                    </svg>
                  </div>
                  <div class="btn">
                    <button
                      type="button"
                      class="link-over"
                      v-on:click="showCancel(item)"
                    >
                      {{ $t("Now staking") }}
                    </button>
                  </div>
                </div>
                <div v-else-if="item.transactionStatus == 'Pending'">
                  <span class="font-span">{{ $t("ON DISPLAY") }}</span>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <!-- ./list-box -->
    <vue-final-modal
      name="cancelModal"
      v-model="cancelModal"
      :resizable="true"
      :adaptive="true"
      :scrollable="true"
      width="100%"
      height="auto"
    >
      <div class="modal-box send-modal">
        <p>
          {{
            $t(
              "If you cancel the staking, a cancellation fee of 5% of your NFT value will be charged."
            )
          }}<br />
          {{ $t("Is it OK to proceed the cancellation?") }}
        </p>
        <div class="img">
          <img src="../assets/img/item-list1-img.png" alt="" />
        </div>
        <div class="flex">
          <div class="detail">
            <p>{{ $t("PRICE") }}</p>
            <div class="label amount">{{ PopBox.min }}<span>USD</span></div>
          </div>
          <div class="detail">
            <p>{{ $t("CANCELLATION FEE") }}</p>
            <div class="label amount">
              {{ Number(PopBox.min) * 0.05 }}<span>USD</span>
            </div>
          </div>
          <div class="detail">
            <p>{{ $t("PASSWORD") }}</p>
            <input
              type="password"
              class="confirm_password"
              :placeholder="$t('Please enter your password')"
              v-model="confirmPassword"
            />
          </div>
        </div>
        <div class="btn">
          <button
            type="button"
            class="link-over not-btn mb-10"
            v-on:click="hideCancelhide()"
          >
            {{ $t("NO") }}
          </button>
          <button
            type="button"
            class="link-over"
            v-on:click="hideCancel(PopBox.id)"
          >
            {{ $t("YES") }}
          </button>
        </div>
      </div>
    </vue-final-modal>
    <vue-final-modal
      name="buyModal"
      v-model="buyModal"
      :resizable="true"
      :adaptive="true"
      :scrollable="true"
      width="100%"
      height="auto"
    >
      <div class="modal-box buy-modal">
        <div class="detail">
          <div class="img">
            <img :src="PopBox.url" alt="" />
          </div>
          <div class="txt">
            <div class="price">$ {{ PopBox.min }}</div>
            <div class="icon">
              <img src="../assets/img/staking-icon-img.svg" alt="" />
            </div>
          </div>
        </div>
        <div class="ttl">{{ $t("Are you sure you want to stake?") }}</div>
        <div class="detail">
          <input
            type="password"
            class="confirm_password"
            :placeholder="$t('Please enter your password')"
            v-model="confirmPassword"
          />
        </div>
        <div class="btn">
          <button type="button" class="link-over not-btn" v-on:click="hideBuy">
            {{ $t("NO") }}
          </button>
          <button
            type="button"
            class="link-over buy-btn"
            v-on:click="StakingFlag(PopBox.id)"
          >
            {{ $t("Staking now") }}
          </button>
        </div>
      </div>
    </vue-final-modal>
  </div>
  <!-- /.contents -->
  <MainFooter />
</template>
<style lang="less" scoped>
.confirm_password {
  border: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 30px;
  padding: 12px 60px 12px 16px;
  font-weight: 600;
  width: 100%;
  margin-bottom: 20px;
  color: #7ed7ff;
}
input::-webkit-input-placeholder {
  color: #7ed7ff;
}
input::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #7ed7ff;
}
input:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #7ed7ff;
}
input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #7ed7ff;
}
.mb-10 {
  margin-bottom: 10px;
}
.mt-10 {
  margin-top: 10px;
}
.font-span {
  color: #fff;
  font-weight: 600;
  width: 100%;
  text-align: center;
  font-size: 16px;
  letter-spacing: 1px;
}
</style>
<script>
import MainHeader from "@/components/MainHeader.vue";
import MainFooter from "@/components/MainFooter.vue";
import Plate from "@/js/api/plate";
export default {
  name: "StakingView",
  components: {
    MainHeader,
    MainFooter,
  },
  data() {
    return {
      status: true,
      buyModal: false,
      cancelModal: false,
      isStaking: false,
      itemList: [],
      PopBox: {}, //点击图片出现弹框的数据存放
      BonusSettingIdList: [], //档区数组
      confirmPassword: null, //解约验证密码
      isActive: "1",
    };
  },
  created() {
    this.createList();
  },
  watch: {
    itemList(value, oldValue) {
      if (oldValue.length != 0) {
        this.$nextTick(function () {
          this.itemList = value;
        });
      }
    },
  },
  methods: {
    isSelect: function (num, id) {
      this.isActive = num;
      let params = {
        BonusSettingId: id,
        index: 0,
        take: 10000,
      };
      Plate.ListMyNft(params)
        .then((response) => {
          this.itemList = response.data.detail;
        })
        .finally(() => (this.loading = false));
    },
    createList() {
      //获取价格档区
      Plate.getListPackageInfo()
        .then((response) => {
          this.BonusSettingIdList = response.data.detail;
        })
        .finally(() => (this.loading = false));
      // 获取数据
      let params = {
        BonusSettingId: "Plage1",
        index: 0,
        take: 10000,
      };
      Plate.ListMyNft(params)
        .then((response) => {
          this.itemList = response.data.detail;
        })
        .finally(() => (this.loading = false));
    },
    showBuy: function (item) {
      this.PopBox = item;
      this.$vfm.show("buyModal");
    },
    hideBuy: function () {
      this.$vfm.hide("buyModal");
    },
    showCancel: function (item) {
      this.PopBox = item;
      this.$vfm.show("cancelModal");
    },
    hideCancel: function (id) {
      Plate.PostReturnStakeNft(id, this.confirmPassword)
        .then((response) => {
          if (response.status == 200) {
            this.$vfm.hide("cancelModal");
            this.isStaking = false;
            this.confirmPassword = null;
            this.PopBox = {};
            setTimeout(function () {
              window.location.reload();
            }, 1000);
          }
        })
        .finally(() => (this.loading = false));
    },
    hideCancelhide: function () {
      this.$vfm.hide("cancelModal");
    },
    StakingFlag: function (id) {
      // let params = {
      //   id: id,
      //   payPassword: this.confirmPassword,
      // };
      Plate.PostStakeNft(id, this.confirmPassword)
        .then((response) => {
          if (response.status == 200) {
            this.$vfm.hide("buyModal");
            this.confirmPassword = null;
            this.PopBox = {};
            setTimeout(function () {
              window.location.reload();
            }, 1000);
          }
        })
        .finally(() => (this.loading = false));
    },
  },
};
</script>
