<template>
  <MainHeader :userName="userName" :status="status" :ap="ap" />
  <div class="contents item-page bg-img">
    <!-- <div class="fv">
      <div class="inner">
        <div class="list-main">
          <div class="txt">
            <h1>Purchase your<br />favorite NFT now!</h1>
            <div class="btn" v-if="!status">
              <router-link to="/signin" class="link-over signin">{{
                $t("SIGN IN")
              }}</router-link>
              <router-link to="/signup" class="link-over signup">{{
                $t("SIGN UP")
              }}</router-link>
            </div>
          </div>
          <div class="img">
            <img src="../assets/img/item-main-img.png" alt="" />
          </div>
        </div>
      </div>
    </div> -->
    <!-- ./fv -->
    <div class="list-box">
      <div class="inner">
        <h2>{{ $t("BUY NFT") }}</h2>
        <ul class="list">
          <li v-for="(item, index) in itemList" :key="index">
            <router-link
              :to="{ path: 'single', query: { id: item.id } }"
              class="link-over"
            >
              <div class="img">
                <img :src="item.url" alt="" />
              </div>
              <div class="txt">
                <!-- <p>{{ item.name }}</p> -->
                <p>
                  $<span>{{ item.min }}</span>
                </p>
              </div>
            </router-link>
          </li>
          <!-- <li>
            <router-link to="/single" class="link-over">
              <div class="img">
                <img src="../assets/img/item-list2-img.svg" alt="" />
              </div>
              <div class="txt">
                <p>ITEM NAME</p>
                <p>$<span>3,000</span></p>
              </div>
            </router-link>
          </li>
          <li>
            <router-link to="/single" class="link-over">
              <div class="img">
                <img src="../assets/img/item-list3-img.svg" alt="" />
              </div>
              <div class="txt">
                <p>ITEM NAME</p>
                <p>$<span>5,000</span></p>
              </div>
            </router-link>
          </li> -->
        </ul>
      </div>
    </div>
    <!-- ./list-box -->
  </div>
  <!-- /.contents -->
  <MainFooter />
</template>

<script>
import MainHeader from "@/components/MainHeader.vue";
import MainFooter from "@/components/MainFooter.vue";
import LoginUser from "@/js/loginUser.js";
import Plate from "@/js/api/plate";
export default {
  name: "HomeView",
  components: {
    MainHeader,
    MainFooter,
  },
  data() {
    return {
      status: false,
      userName: null,
      ap: null, //点数
      itemList: [],
      loading: false,
    };
  },
  created() {
    this.create();
  },
  methods: {
    create() {
      let token = localStorage.getItem("accessToken");

      Plate.getListPackageInfo()
        .then((response) => {
          this.itemList = response.data.detail;
          // console.log(this.itemList);
          if (response.status == 200) {
            if (token != null) {
              this.status = true;
              this.userName = LoginUser.getUserName();
              this.ap = LoginUser.getap();
            }
          }
        })
        .finally(() => (this.loading = false));
      // this.$router.push({ path: "./home" });
    },
  },
};
</script>
