<template>
  <div class="wrapper">
    <router-view :key="key" />
  </div>
</template>

<style lang="less">
@import "./assets/css/style.css";
</style>

<script>
export default {
  computed: {
    // 解决路径相同参数不同不跳转的问题
    key() {
      console.log(this.$route.name);
      if (this.$route.name == "Home" || this.$route.name == "home") {
        return this.$route.name ? this.$route.name : this.$route;
      } else {
        return this.$route.name
          ? this.$route.name + +new Date()
          : this.$route + +new Date();
      }
    },
  },
  mounted: function () {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", vh + "px");

    var ua = navigator.userAgent.toLowerCase();
    var isiOS = ua.indexOf("iphone") > -1 || ua.indexOf("ipad") > -1;
    if (isiOS) {
      var viewport = document.querySelector('meta[name="viewport"]');
      if (viewport) {
        var viewportContent = viewport.getAttribute("content");
        viewport.setAttribute(
          "content",
          viewportContent + ", user-scalable=no"
        );
      }
    }
  },
};
</script>
