<template>
  <div class="contents signin-page reset-page">
    <div class="box">
      <div class="inner">
        <h1>{{ $t("Resetting a password") }}</h1>
        <form @submit.prevent>
          <div class="input-area">
            <input
              type="text"
              name=""
              v-model="username"
              autocomplete=""
              :placeholder="$t('USER NAME')"
              required
            />
          </div>
          <!-- <div class="input-area">
            <input
              type="email"
              name=""
              v-model="email"
              autocomplete=""
              :placeholder="$t('EMAIL')"
              required
            />
          </div> -->
          <div class="form-btn">
            <button v-on:click="submit" type="button" class="link-over">
              {{ $t("DONE") }}
            </button>
          </div>
        </form>
      </div>
    </div>
    <!-- <Footer /> -->
  </div>

  <!-- /.contents -->
</template>

<script>
import { i18n } from "@/i18n/i18n";
import Account from "@/js/api/account";
// import Footer from "@/components/Footer.vue";
export default {
  name: "PasswordReset",
  components: {
    // Footer,
  },
  data() {
    return {
      username: "",
      // email: "",
    };
  },
  created() {
    console.log(i18n);
  },
  methods: {
    submit() {
      Account.getExistedUser(this.username).then((res) => {
        this.loading = false;
        if (res) {
          if (res.data) {
            this.$router.push({
              path: "passwordinput",
              query: {
                username: this.username,
              },
            });
          } else {
            this.userNameIsError = true;
          }
        }
      });
    },
  },
};
</script>
