<template>
  <MainHeader :status="status" />
  <div class="contents team-page bg-back">
    <div class="page-ttl">
      <div class="inner">
        <h1>{{ $t("TEAM") }}</h1>
        <div class="link">
          <router-link to="/organizationteam" class="link-over">{{
            $t("Organization")
          }}</router-link>
          <router-link to="/referralteam" class="link-over active">{{
            $t("REFERRAL")
          }}</router-link>
        </div>
      </div>
    </div>
    <div class="referral-box">
      <div class="inner">
        <div class="code-area">
          <p>{{ $t("REFERRAL CODE") }}</p>
          <div class="label">
            <input type="text" :value="code" readonly />
            <span
              class="link-over"
              v-clipboard:copy="code"
              v-clipboard:success="onCopySuccess"
              v-clipboard:error="onCopyError"
            >
              <img src="../assets/img/copy-icon-img.svg" alt="" />
            </span>
          </div>
          <p>{{ $t("REFERRAL URL") }}</p>
          <div class="label">
            <input type="text" :value="url" readonly />
            <span
              class="link-over"
              v-clipboard:copy="url"
              v-clipboard:success="onCopySuccess"
              v-clipboard:error="onCopyError"
            >
              <img src="../assets/img/copy-icon-img.svg" alt="" />
            </span>
          </div>
        </div>
        <div class="qr-area">
          <p>{{ $t("REFERRAL QR") }}</p>
          <div class="label">
            <div class="img">
              <QRCodeVue3
                :width="200"
                :height="200"
                :value="url"
                :qrOptions="{
                  typeNumber: 11,
                  mode: 'Byte',
                  errorCorrectionLevel: 'H',
                }"
                :imageOptions="{
                  hideBackgroundDots: true,
                  imageSize: 0.4,
                  margin: 0,
                }"
                :dotsOptions="{
                  type: 'classy',
                }"
              />
            </div>
            <div class="btn">
              <button type="button" class="link-over">{{ $t("COPY") }}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- ./ -->
  </div>
  <!-- /.contents -->
  <MainFooter />
</template>

<script>
import MainHeader from "@/components/MainHeader.vue";
import MainFooter from "@/components/MainFooter.vue";
import LoginUser from "@/js/loginUser.js";
import Common from "@/js/common";
import { Notify } from "vant";
import QRCodeVue3 from "qrcode-vue3";
export default {
  name: "ReferralTeam",
  components: {
    MainHeader,
    MainFooter,
    QRCodeVue3,
  },
  data() {
    return {
      status: true,
      code: LoginUser.getUserName(),
      url: Common.hostUrl + "/#/Signup?Introducer=" + LoginUser.getUserName(),
    };
  },
  methods: {
    onCopySuccess() {
      Notify({ type: "success", message: this.$t("Copy successfully") });
    },
    onCopyError() {
      Notify({ type: "danger", message: this.$t("Copy failed") });
    },
  },
};
</script>
