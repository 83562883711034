<template>
  <MainHeader :status="status" />
  <div class="contents transfer-page bg-back">
    <div class="page-ttl">
      <div class="inner">
        <h1>TRANSFER</h1>
        <div class="link">
          <router-link to="/usdttransfer" class="link-over">USD</router-link>
          <router-link to="/itemtransfer" class="link-over active"
            >ITEM</router-link
          >
        </div>
      </div>
    </div>
    <div class="tab-area">
      <div class="inner">
        <div class="tab">
          <span
            class="link-over"
            v-on:click="isSelect('1')"
            v-bind:class="{
              active: isActive === '1',
            }"
            >SEND</span
          >
          <span
            class="link-over"
            v-on:click="isSelect('2')"
            v-bind:class="{
              active: isActive === '2',
            }"
            >RECEIVE</span
          >
        </div>
      </div>
    </div>
    <div
      class="tab-contents"
      v-bind:class="{
        active: isActive === '1',
      }"
    >
      <div class="box">
        <div class="inner">
          <div class="balance-area">
            <p>ITEM</p>
            <div class="label">
              <input type="file" name="" id="file" />
              <label for="file" class="link-over">Select</label>
            </div>
          </div>
          <div class="form-area">
            <p>TRANSFER DETAIL</p>
            <form>
              <div class="input-area">
                <input type="text" name="" placeholder="USERNAME" />
              </div>
              <div class="input-area">
                <input type="number" name="" placeholder="price" />
              </div>
              <div class="btn">
                <button type="button" class="link-over" v-on:click="showSend">
                  TRANSFER
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div class="list-box">
        <div class="inner">
          <h2>STAND-BY</h2>
          <ul class="list">
            <li>
              <router-link to="#" class="link-over">
                <div class="img">
                  <img src="../assets/img/item-list1-img.svg" alt="" />
                </div>
                <div class="txt">
                  <p><b>TO</b>test user</p>
                  <p>$<span>1,200</span></p>
                </div>
              </router-link>
              <div class="btn">
                <button type="button" class="link-over">Cancel</button>
              </div>
            </li>
            <li>
              <router-link to="#" class="link-over">
                <div class="img">
                  <img src="../assets/img/item-list2-img.svg" alt="" />
                </div>
                <div class="txt">
                  <p><b>TO</b>test user</p>
                  <p>$<span>1,200</span></p>
                </div>
              </router-link>
              <div class="btn">
                <button type="button" class="link-over">Cancel</button>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <!-- ./list-box -->
    </div>
    <div
      class="tab-contents"
      v-bind:class="{
        active: isActive === '2',
      }"
    >
      <div class="box">
        <div class="inner">
          <div class="stand-area">
            <p>STAND-BY</p>
            <div class="list-box">
              <div
                class="link-over receive-list"
                v-on:click="receiveSelect('1')"
                v-bind:class="{
                  active: receiveActive === '1',
                }"
              >
                <p class="name">testuser</p>
                <div class="img">
                  <img src="../assets/img/item-list1-img.svg" alt="" />
                </div>
                <div class="price">$1,200</div>
              </div>
              <div
                class="link-over receive-list"
                v-on:click="receiveSelect('2')"
                v-bind:class="{
                  active: receiveActive === '2',
                }"
              >
                <p class="name">testuser2</p>
                <div class="img">
                  <img src="../assets/img/item-list1-img.svg" alt="" />
                </div>
                <div class="price">$1,200</div>
              </div>
              <div
                class="link-over receive-list"
                v-on:click="receiveSelect('3')"
                v-bind:class="{
                  active: receiveActive === '3',
                }"
              >
                <p class="name">testuser3</p>
                <div class="img">
                  <img src="../assets/img/item-list1-img.svg" alt="" />
                </div>
                <div class="price">$1,200</div>
              </div>
              <div class="btn">
                <router-link to="/itemhistory" class="link-over"
                  >History</router-link
                >
              </div>
            </div>
          </div>
          <div class="form-area">
            <p>TRANSFER DETAIL</p>
            <form>
              <div class="input-area">
                <input
                  type="text"
                  name=""
                  placeholder=""
                  :value="currentName"
                />
              </div>
              <div class="input-area usdt">
                <input
                  type="number"
                  name=""
                  placeholder=""
                  :value="currentPrice"
                />
                <span>USD</span>
              </div>
              <div class="balance">Blance<span>$10,000</span>usdt</div>
              <div class="btn">
                <button
                  type="button"
                  class="link-over"
                  :disabled="currentName === '' && currentPrice === ''"
                  v-on:click="showBuy"
                >
                  payment
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- ./ -->
    <vue-final-modal
      name="sendModal"
      v-model="sendModal"
      :resizable="true"
      :adaptive="true"
      :scrollable="true"
      width="100%"
      height="auto"
    >
      <div class="modal-box send-modal">
        <p>Are you sure you want to send NFT?</p>
        <div class="img">
          <img src="../assets/img/item-list1-img.svg" alt="" />
        </div>
        <div class="flex">
          <div class="detail">
            <p>TO</p>
            <div class="label">TESTUSER</div>
          </div>
          <div class="detail">
            <p>AMOUNT</p>
            <div class="label amount">1,200<span>USD</span></div>
          </div>
        </div>
        <div class="btn">
          <button type="button" class="link-over" v-on:click="hideSend">
            YES
          </button>
        </div>
      </div>
    </vue-final-modal>
    <vue-final-modal
      name="buyModal"
      v-model="buyModal"
      :resizable="true"
      :adaptive="true"
      :scrollable="true"
      width="100%"
      height="auto"
    >
      <div class="modal-box buy-modal">
        <div class="detail">
          <div class="img">
            <img src="../assets/img/item-list1-img.svg" alt="" />
          </div>
          <div class="txt">
            <div class="price">$1,000</div>
            <div class="balance">BALANCE $1,500</div>
            <div class="icon">
              <img src="../assets/img/item-download-img.svg" alt="" />
            </div>
          </div>
        </div>
        <div class="ttl">Are you sure you want to buy?</div>
        <div class="btn">
          <button type="button" class="link-over not-btn" v-on:click="hideBuy">
            No
          </button>
          <button
            type="button"
            class="link-over buy-btn"
            v-on:click="showComplete"
          >
            Buy now
          </button>
        </div>
      </div>
    </vue-final-modal>
    <vue-final-modal
      name="completeModal"
      v-model="completeModal"
      :resizable="true"
      :adaptive="true"
      :scrollable="true"
      width="100%"
      height="auto"
    >
      <div class="modal-box complete-modal">
        <div class="txt">Congratulation!!</div>
        <div class="img">
          <img src="../assets/img/item-list1-img.svg" alt="" />
        </div>
        <div class="btn">
          <router-link to="./wallet" class="link-over" v-on:click="hideComplete"
            >MY NFT</router-link
          >
        </div>
        <div class="balloons">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </vue-final-modal>
  </div>
  <!-- /.contents -->
  <MainFooter />
</template>

<script>
import MainHeader from "@/components/MainHeader.vue";
import MainFooter from "@/components/MainFooter.vue";

export default {
  name: "ItemTransfer",
  components: {
    MainHeader,
    MainFooter,
  },
  data() {
    return {
      status: true,
      isActive: "1",
      sendModal: false,
      buyModal: false,
      completeModal: false,
      receiveActive: "0",
      currentName: "",
      currentPrice: "",
    };
  },
  methods: {
    isSelect: function (num) {
      this.isActive = num;
    },
    receiveSelect: function (num) {
      this.receiveActive = num;
      this.currentName = "testuser";
      this.currentPrice = "1200";
    },
    showSend: function () {
      this.$vfm.show("sendModal");
    },
    hideSend: function () {
      this.$vfm.hide("sendModal");
    },
    showBuy: function () {
      this.$vfm.show("buyModal");
    },
    hideBuy: function () {
      this.$vfm.hide("buyModal");
    },
    showComplete: function () {
      this.$vfm.show("completeModal");
      this.$vfm.hide("buyModal");
    },
    hideComplete: function () {
      this.$vfm.hide("completeModal");
    },
  },
};
</script>
