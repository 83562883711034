<template>
  <footer class="footer">
    <div class="language">
      <div class="change-box">
        <select
          name=""
          id=""
          v-model="currentLanguage"
          v-on:change.prevent="changeLanguage($event)"
        >
          <option value="en-us">English</option>
          <option value="ja-jp">Japanese</option>
          <option value="zh-cn">Chinese</option>
        </select>
      </div>
    </div>
    <p class="copyright">
      {{ $t("Copyright by ChuChuPark") }}
    </p>
    <!-- <Footeri18n class="position_Footer" /> -->
  </footer>
</template>

<script>
// import Footeri18n from "./Footer";
import { setLanguage } from "../i18n/i18n";
import Definitions from "../js/definitions";
export default {
  name: "MainFooter",
  data() {
    return {
      currentLanguage: localStorage.getItem(Definitions.StorageKeys.language),
    };
  },
  props: {},
  // components: {
  //   Footeri18n,
  // },
  methods: {
    changeLanguage(e) {
      setLanguage(e.target.value);

      this.currentLanguage = e.target.value;
    },
  },
};
</script>

<style scoped lang="less"></style>
