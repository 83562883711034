<template>
  <MainHeader :status="status" />
  <div class="contents exchange-page bg-back">
    <div class="page-ttl">
      <div class="inner">
        <h1>{{ $t("EXCHANGE") }}</h1>
      </div>
    </div>
    <div class="box">
      <div class="inner">
        <div class="balance-area">
          <p>{{ $t("BALANCE") }}</p>
          <div class="label">
            <b>{{ this.userinfo.ap }}</b>
            <span>USD</span>
          </div>
          <div class="label">
            <b>{{ this.userinfo.standardToken }}</b>
            <span>CCP</span>
          </div>
          <div class="label">
            <b>{{ this.userinfo.rp }}</b>
            <span>CCT</span>
          </div>
        </div>
        <div class="form-area">
          <p>{{ $t("Exchange detail") }}</p>
          <form>
            <div class="flex">
              <div class="input-area select">
                <select
                  name=""
                  id=""
                  v-model="fromAsset"
                  @change="selectChange"
                >
                  <option value="STANDARDTOKEN">CCP</option>
                  <option value="AP">USD</option>
                </select>
              </div>
              <div class="img">
                <img src="../assets/img/exchange-icon-img.svg" alt="" />
              </div>
              <div class="input-area select">
                <select name="" id="" v-model="ToAsset">
                  <option v-if="fromAsset == 'STANDARDTOKEN'" value="AP">
                    USD
                  </option>
                  <option v-else-if="fromAsset == 'AP'" value="RP">CCT</option>
                </select>
              </div>
            </div>
            <div class="input-area">
              <input
                type="number"
                name=""
                :placeholder="$t('Amount')"
                v-model="changeAmount"
              />
            </div>
            <!-- <div class="all-amount"><span>- - -</span>CCT</div> -->
            <div class="btn">
              <button
                type="button"
                class="link-over bg-color"
                v-on:click="show"
                ref="btnRef"
                disabled="true"
              >
                {{ $t("EXCHANGE") }}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <!-- ./ -->
    <vue-final-modal
      name="modal"
      v-model="showModal"
      :resizable="true"
      :adaptive="true"
      :scrollable="true"
      width="100%"
      height="auto"
    >
      <div class="modal-box">
        <p>{{ $t("Are you sure, you want to exchange?") }}</p>
        <div class="detail">
          <p>{{ $t("AMOUNT") }}</p>
          <div v-if="fromAsset == 'STANDARDTOKEN'" class="label amount">
            {{ this.changeAmount }}<span>CCP</span>
          </div>
          <div v-else class="label amount">
            {{ this.changeAmount }}<span>USD</span>
          </div>
        </div>
        <div class="btn">
          <button type="button" class="link-over mb-10" v-on:click="hideNo">
            {{ $t("NO") }}
          </button>
          <button type="button" class="link-over" v-on:click="hide">
            {{ $t("YES") }}
          </button>
        </div>
      </div>
    </vue-final-modal>
  </div>
  <!-- /.contents -->
  <MainFooter />
</template>
<style lang="less" scoped>
.mb-10 {
  margin-bottom: 10px;
}
.bg-color {
  background-color: #e3e4e4 !important;
}
</style>
<script>
import MainHeader from "@/components/MainHeader.vue";
import MainFooter from "@/components/MainFooter.vue";
import LoginUser from "@/js/loginUser.js";
import asset from "@/js/api/asset";
import { i18n } from "@/i18n/i18n";
import Common from "../js/common";
export default {
  name: "ExchangeView",
  components: {
    MainHeader,
    MainFooter,
  },
  data() {
    return {
      status: true,
      showModal: false,
      userinfo: LoginUser.getUserInfo(),
      changeAmount: "",
      ToAsset: "AP",
      fromAsset: "STANDARDTOKEN",
    };
  },
  watch: {
    changeAmount(value) {
      if (value > 0) {
        this.$refs.btnRef.disabled = false;
        this.$refs.btnRef.style = "background-color:#7ed7ff !important";
      } else {
        this.$refs.btnRef.style = "background-color:#e3e4e4 !important";
        this.$refs.btnRef.disabled = true;
      }
    },
  },
  methods: {
    selectChange() {
      if (this.fromAsset == "STANDARDTOKEN") {
        this.ToAsset = "AP";
      } else {
        this.ToAsset = "RP";
      }
    },
    show: function () {
      this.$vfm.show("modal");
    },
    hideNo() {
      this.$vfm.hide("modal");
    },
    hide: function () {
      let params = {
        ToAsset: this.ToAsset,
        fromAsset: this.fromAsset,
        FromPoint: this.changeAmount,
      };
      // console.log(params);
      asset
        .PostPointToPoint(params)
        .then((response) => {
          // console.log(response);
          if (response.status == 200) {
            Common.success(i18n.global.t("Exchange of success"));
            setTimeout(function () {
              window.location.reload();
            }, 2000);
          }
        })
        .finally(() => (this.loading = false));

      this.$vfm.hide("modal");
    },
  },
};
</script>
