<template>
  <header class="header">
    <div class="inner">
      <div class="top-area">
        <router-link to="/" class="link-over logo">
          <img src="../assets/img/logo-img.svg" alt="Logo" />
        </router-link>
        <div class="btn" v-bind:class="[status === true ? 'column' : '']">
          <div class="status" v-bind:class="[status === true ? '' : 'active']">
            <router-link to="/signin" class="link-over signin">{{
              $t("SIGN IN")
            }}</router-link>
            <router-link to="/signup" class="link-over signup">{{
              $t("SIGN UP")
            }}</router-link>
          </div>
          <div class="status" v-bind:class="[status === true ? 'active' : '']">
            <div class="user-info">
              <p>
                <span>{{ this.ap }}</span
                >{{ $t("USD") }}
              </p>
            </div>
          </div>
          <div class="sp-only">
            <div class="sp-menu link-over" v-on:click="toggleMenu()">
              <div class="img">
                <img src="../assets/img/copy-icon2-img.svg" alt="" />
              </div>
              <span>{{ $t("Menu") }}</span>
            </div>
          </div>
        </div>
      </div>
      <div
        class="bottom-area"
        v-bind:class="[menuActive === true ? 'active' : '']"
      >
        <div class="close-btn sp-only link-over" v-on:click="toggleMenu()">
          <span class="line line1"></span>
          <span class="line line2"></span>
        </div>
        <nav class="g-nav">
          <div class="sp-only">
            <p class="sp-nav-ttl">
              <router-link to="/" class="link-over" v-on:click="toggleMenu()">{{
                $t("ChuChuPark TOP")
              }}</router-link>
            </p>
          </div>
          <div v-if="status === false">
            <div class="sp-only">
              <div class="btn">
                <router-link to="/signup" class="link-over signup">{{
                  $t("SIGN UP")
                }}</router-link>
                <router-link to="/signin" class="link-over signin">{{
                  $t("SIGN IN")
                }}</router-link>
              </div>
            </div>
          </div>
          <div class="pc-only">
            <ul class="list">
              <li v-if="status === true">
                <router-link to="/" class="link-over">{{
                  $t("TOP")
                }}</router-link>
              </li>
              <li
                class="cursor"
                v-on:mouseover="nftMouseover"
                v-on:mouseleave="nftMouseleave"
                v-if="status === true"
              >
                <span :class="{ isOpen }">{{ $t("NFT") }}</span>
                <div class="hover-menu" :class="{ isOpen }">
                  <div class="link">
                    <router-link to="/wallet" class="link-over">{{
                      $t("MY NFT")
                    }}</router-link>
                  </div>
                  <div class="link">
                    <router-link to="/staking" class="link-over">{{
                      $t("STAKING")
                    }}</router-link>
                  </div>
                  <div class="link">
                    <router-link to="/item" class="link-over">{{
                      $t("NFT")
                    }}</router-link>
                  </div>
                  <div class="link">
                    <router-link to="/market" class="link-over">{{
                      $t("MART")
                    }}</router-link>
                  </div>
                </div>
              </li>
              <li v-if="status === true">
                <router-link to="/deposit" class="link-over">{{
                  $t("DEPOSIT")
                }}</router-link>
              </li>
              <li v-if="status === true">
                <router-link to="/Withdraw" class="link-over">{{
                  $t("WITHDRAW")
                }}</router-link>
              </li>
              <li v-if="status === true">
                <router-link to="/transactionhistory" class="link-over">{{
                  $t("HISTORY")
                }}</router-link>
              </li>
              <li v-if="status === true">
                <router-link to="/organizationteam" class="link-over">{{
                  $t("TEAM")
                }}</router-link>
              </li>
              <li v-if="status === true">
                <router-link to="/usdttransfer" class="link-over">{{
                  $t("TRANSFER")
                }}</router-link>
              </li>
              <li v-if="status === true">
                <router-link to="/exchange" class="link-over">{{
                  $t("EXCHANGE")
                }}</router-link>
              </li>
              <li v-if="status === true">
                <router-link to="/account" class="link-over">{{
                  $t("ACCOUNT")
                }}</router-link>
              </li>
              <li>
                <router-link to="#" class="link-over" @click="loggedOut">{{
                  $t("SIGN OUT")
                }}</router-link>
              </li>
            </ul>
          </div>
          <div class="sp-only">
            <ul class="sp-header-menu">
              <li class="list1">
                <router-link to="/wallet" class="link-over">{{
                  $t("MY NFT")
                }}</router-link>
              </li>
              <li class="list2">
                <router-link to="/item" class="link-over">{{
                  $t("NFT")
                }}</router-link>
              </li>
              <li class="list3">
                <router-link to="/deposit" class="link-over">{{
                  $t("DEPOSIT")
                }}</router-link>
              </li>
              <li class="list4">
                <router-link to="/withdraw" class="link-over">{{
                  $t("WITHDRAW")
                }}</router-link>
              </li>
              <li class="list5">
                <router-link to="/itemhistory" class="link-over">{{
                  $t("HISTORY")
                }}</router-link>
              </li>
              <li class="list6">
                <router-link to="/organizationteam" class="link-over">{{
                  $t("TEAM")
                }}</router-link>
              </li>
              <li class="list7">
                <router-link to="/usdttransfer" class="link-over">{{
                  $t("TRANSFER")
                }}</router-link>
              </li>
              <li class="list8">
                <router-link to="/exchange" class="link-over">{{
                  $t("EXCHANGE")
                }}</router-link>
              </li>
              <li class="all list12">
                <router-link to="/market" class="link-over">{{
                  $t("ChuChuMart")
                }}</router-link>
              </li>
              <li class="all list9">
                <router-link to="/staking" class="link-over">{{
                  $t("STAKING")
                }}</router-link>
              </li>
              <li class="all list10">
                <router-link to="/account" class="link-over">{{
                  $t("ACCOUNT")
                }}</router-link>
              </li>
              <li class="all list11">
                <router-link to="#" class="link-over" @click="loggedOut">{{
                  $t("SIGN OUT")
                }}</router-link>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </div>
  </header>
</template>

<script>
import LoginUser from "@/js/loginUser.js";
import Plate from "@/js/api/plate";
import Common from "@/js/common";
export default {
  name: "MainHeader",
  props: {
    status: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      menuActive: false,
      ap: null, //点数
      isLogin: false,
      isOpen: false,
    };
  },
  created() {
    let token = localStorage.getItem("accessToken");
    if (token) {
      this.isLogin = true;
    }
    Plate.getListPackageInfo()
      .then((response) => {
        this.itemList = response.data.detail;
        // console.log(this.itemList);
        if (response.status == 200) {
          if (token != null) {
            this.ap = LoginUser.getap();
          }
        }
      })
      .finally(() => (this.loading = false));
  },
  methods: {
    toggleMenu() {
      this.menuActive = !this.menuActive;
    },
    loggedOut() {
      Common.loggedOut();
    },
    nftMouseover() {
      this.isOpen = true;
    },
    nftMouseleave: function () {
      this.isOpen = false;
    },
  },
};
</script>

<style scoped lang="less"></style>
