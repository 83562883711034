import Fetch from "@/js/api/fetch";

const Plate = {};

Plate.getPlateInfo = () => Fetch.get("/Plate/GetPlateInfo");

Plate.activePlate = (data) => Fetch.post("/Plate/ActivePlate", data);

Plate.getListPackageInfo = (data) => Fetch.get("Plate/ListPackageInfo", data);

Plate.GetPackageInfo = (bonusSettingId) =>
  Fetch.get("Plate/GetPackageInfo", { params: { bonusSettingId } });

Plate.ActivePlate = (params) => Fetch.post("Plate/ActivePlate", params);

Plate.ListMyNft = (data) =>
  Fetch.get("Plate/ListMyNft", {
    params: data,
  });
Plate.PostStakeNft = (id, payPassword) =>
  Fetch.post(
    `Plate/StakeNft?id=${id}&payPassword=${encodeURIComponent(payPassword)}`
  );

Plate.PostReturnStakeNft = (id, payPassword) =>
  Fetch.post(
    `Plate/ReturnStakeNft?id=${id}&payPassword=${encodeURIComponent(
      payPassword
    )}`
  );

Plate.PostPutOnNft = (id, price) =>
  Fetch.post(`Plate/PutOnNft?id=${id}&price=${price}`);

Plate.PostCancelPutOnNft = (id) => Fetch.post(`Plate/CancelPutOnNft?id=${id}`);

Plate.getListMarketNft = (params) =>
  Fetch.get(
    `Plate/ListMarketNft?bonusSettingId=${params.BonusSettingId}&index=${
      Number(params.index) - 1
    }&take=${params.take}${
      params.fromPrice ? `&fromPrice=` + params.fromPrice : ""
    }${params.toPrice ? `&toPrice=` + params.toPrice : ""}${
      params.userName ? `&userName=` + params.userName : ""
    }`
  );
Plate.PostBuyNft = (params) => Fetch.post(`Plate/BuyNft`, params);
export default Plate;
