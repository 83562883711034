<template>
  <MainHeader :status="status" />
  <div class="contents team-page bg-back">
    <div class="page-ttl">
      <div class="inner">
        <h1>{{ $t("TEAM") }}</h1>
        <div class="link">
          <router-link to="/organizationteam" class="link-over active">{{
            $t("Organization")
          }}</router-link>
          <router-link to="/referralteam" class="link-over">{{
            $t("REFERRAL")
          }}</router-link>
        </div>
      </div>
    </div>
    <div class="toggle-btn-area">
      <div class="inner">
        <p>{{ $t("MAP MODE") }}</p>
        <Toggle v-model="value" on-label="ON" off-label="OFF" />
      </div>
    </div>
    <div v-if="!value">
      <div class="organization-box">
        <div class="inner">
          <div class="info-area">
            <div class="wrap">
              <p>{{ $t("YOUR TEAM") }}</p>
              <div class="label">
                <span>{{ teamList.teamCount }}</span
                >USER
              </div>
            </div>
            <div class="wrap">
              <p>{{ $t("YOUR REFERRAL") }}</p>
              <div class="label">
                <span>{{ teamList.introduceCount }}</span
                >USER
              </div>
            </div>
            <div class="wrap">
              <p>{{ $t("TEAM OUTCOME") }}</p>
              <div class="label">
                <span>{{ teamList.teamPackage }}</span
                >USD
              </div>
            </div>
          </div>
          <div class="table-area">
            <h2>
              <span
                v-for="(item, i) in tableTitleObj"
                :key="i"
                @click="tableTitleClick(i)"
                >{{ item }} ></span
              >
            </h2>
            <table class="table">
              <tr>
                <th>{{ $t("USER NAME") }}</th>
                <th>{{ $t("STATUS") }}</th>
                <th>{{ $t("REFERRALS") }}</th>
                <th>{{ $t("AMOUNT") }}</th>
              </tr>
              <tr
                v-for="(item, i) in underLineList"
                :key="i"
                @click="nextItemClick(item.userName, item.downLineCount)"
              >
                <td
                  class="name"
                  :class="item.packagesInfo != null ? 'active' : ''"
                  v-if="item.downLineCount > 0"
                >
                  <router-link to="/organizationteam" class="link-over">{{
                    item.userName
                  }}</router-link>
                  <div class="num-list">
                    <span
                      v-for="(itemTwo, index) in item.packagesInfo"
                      :key="index"
                    >
                      <span v-if="index == 1">$1,000</span>
                      <span v-if="index == 2">$5,000</span>
                      <span v-if="index == 3">$10,000</span>
                      <span v-if="index == 4">$50,000</span>

                      :{{ itemTwo }}</span
                    >
                  </div>
                </td>
                <td
                  class="name"
                  :class="item.packagesInfo != null ? 'active' : ''"
                  v-else
                >
                  {{ item.userName }}
                </td>
                <td class="status" v-if="item.stage > 0">ACTIVE</td>
                <td class="status not-active" v-else>NOT&nbsp;ACTIVE</td>
                <td>{{ item.downLineCount }}</td>
                <td class="amount" v-if="item.downLineCount">{{ item.ap }}</td>
                <td class="amount not-active" v-else>
                  {{ item.ap }}
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div v-else-if="value === true">
      <div class="chart-box">
        <div class="inner">
          <div class="info-area">
            <div class="wrap">
              <p>{{ $t("YOUR TEAM") }}</p>
              <div class="label">
                <span>{{ teamList.teamCount }}</span
                >USER
              </div>
            </div>
            <div class="wrap">
              <p>{{ $t("YOUR REFERRAL") }}</p>
              <div class="label">
                <span>{{ teamList.introduceCount }}</span
                >USER
              </div>
            </div>
            <div class="wrap">
              <p>{{ $t("TEAM OUTCOME") }}</p>
              <div class="label">
                <span>{{ teamList.teamPackage }}</span
                >USD
              </div>
            </div>
          </div>
          <div class="chart-area">
            <a @click="BackTop">{{ $t("Back to the top") }}</a>
            <organization-chart :datasource="ListSeatUser">
              <template v-slot="{ nodeData }">
                <div class="chart-img">
                  <img src="../assets/img/chart-icon-img.svg" alt="" />
                </div>
                <div class="chart-name">{{ nodeData.userName }}</div>
                <div class="hidden-desc">
                  <span>{{ $t("UserName") }}:{{ nodeData.userName }}</span>
                  <dl class="list">
                    <dt>{{ $t("MY NFT") }}</dt>
                    <dd>{{ nodeData.stage }} USD</dd>
                  </dl>
                  <!-- <dl class="list">
                    <dt>{{ $t("Group total") }}</dt>
                    <dd>{{ nodeData.performance1 }} USD</dd>
                  </dl> -->
                  <div class="num-list">
                    <span v-for="(item, i) in nodeData.packagesInfo" :key="i">
                      <span v-if="i == 1">$1,000:{{ item }}</span>
                      <span v-if="i == 2">$5,000:{{ item }}</span>
                      <span v-if="i == 3">$10,000:{{ item }}</span>
                      <span v-if="i == 4">$50,000:{{ item }}</span>
                    </span>
                  </div>
                </div>
              </template>
            </organization-chart>
          </div>
        </div>
      </div>
    </div>
    <!-- ./ -->
  </div>
  <!-- /.contents -->
  <MainFooter />
</template>
<!-- <style lang="less" scoped>
.team-page .organization-box .table-area .table td.not-active:before {
  width: 0px !important;
}
</style> -->
<style src="@vueform/toggle/themes/default.css"></style>
<style lang="less" scoped>
.team-page .organization-box .table-area .table td.not-active:before {
  width: 0px;
  height: 0px;
}
</style>
<script>
import MainHeader from "@/components/MainHeader.vue";
import MainFooter from "@/components/MainFooter.vue";
import Account from "@/js/api/account";
import LoginUser from "@/js/loginUser.js";
import OrganizationChart from "vue3-organization-chart";
import Toggle from "@vueform/toggle";
import "vue3-organization-chart/dist/orgchart.css";
export default {
  name: "OrganizationTeam",
  components: {
    MainHeader,
    MainFooter,
    Toggle,
    OrganizationChart,
  },
  data() {
    return {
      status: true,
      currentPage: 1,
      teamList: {},
      isNext: true,
      value: false,
      userName: "",
      underLineList: [],
      tableTitleObj: [],
      ListSeatUser: {},
    };
  },
  created() {
    let token = localStorage.getItem("accessToken");
    if (token != null) {
      this.userName = LoginUser.getUserName();
      this.tableTitleObj.push(this.userName);
    }
    this.GetTeamInfo();
    this.GetUnderLine();
    this.GetListSeatUser();
  },
  methods: {
    //回到顶部
    BackTop() {
      Account.GetListSeatUser(this.userName).then((res) => {
        if (res) {
          this.ListSeatUser = res.data;
          this.ListSeatUser.id = "user1";
        }
      });
    },
    // 组织图下一层
    // GoSubstratum(name) {
    //   Account.GetListSeatUser(name).then((res) => {
    //     if (res) {
    //       this.ListSeatUser = res.data;
    //       this.ListSeatUser.id = "user1";
    //     }
    //   });
    // },
    GetListSeatUser() {
      Account.GetListSeatUser(this.userName).then((res) => {
        if (res) {
          this.ListSeatUser = res.data;
          this.ListSeatUser.id = "user1";
          console.log(this.ListSeatUser);
        }
      });
    },
    GetTeamInfo() {
      Account.GetTeamInfo().then((res) => {
        if (res) {
          this.teamList = res.data;
        }
      });
    },
    GetUnderLine(name) {
      var uname = null;
      if (name != null) {
        uname = name;
      } else {
        uname = this.userName;
      }
      Account.UnderLine(uname, this.isNext).then((res) => {
        if (res) {
          this.underLineList = res.data.detail;
        }
      });
    },
    nextItemClick(userName, isNext) {
      if (isNext > 0) {
        this.tableTitleObj.push(userName);
        this.GetUnderLine(userName);
      } else {
        console.log(userName);
      }
    },
    tableTitleClick(i) {
      this.GetUnderLine(this.tableTitleObj[i]);
      this.tableTitleObj.splice(i + 1);
    },
  },
};
</script>
