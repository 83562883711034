<template>
  <MainHeader :status="status" />
  <div class="contents my-page bg-back">
    <div class="fv">
      <div class="inner">
        <div class="box">
          <div class="ccp-number">
            <span>{{ this.userinfo.standardToken }}</span
            >CCP
          </div>
          <div class="label-list">
            <div class="other-number">
              USD<span>{{ this.userinfo.ap }}</span>
            </div>
            <div class="other-number">
              CCT<span>{{ this.userinfo.rp }}</span>
            </div>
          </div>
          <div class="tab-list">
            <div class="tab">
              <span
                class="link-over"
                v-on:click="isActive = '1'"
                v-bind:class="{
                  active: isActive === '1',
                }"
                >{{ $t("CURRENT MONTH SALES") }}</span
              >
              <span
                class="link-over"
                v-on:click="isActive = '2'"
                v-bind:class="{
                  active: isActive === '2',
                }"
                >{{ $t("CUMMULATIVE SALES") }}</span
              >
            </div>
            <div class="tab-contents" v-if="isActive === '1'">
              <ul class="list">
                <li>
                  <span class="headline">1段目</span>
                  <p>{{ userPerformanceInfoList.performance1 }}</p>
                  <span class="currency">USD</span>
                </li>
              </ul>
            </div>
            <div class="tab-contents" v-if="isActive === '2'">
              <ul class="list">
                <li>
                  <span class="headline">1段目</span>
                  <p>{{ userPerformanceInfoList.performance1Total }}</p>
                  <span class="currency">USD</span>
                </li>
                <li>
                  <span class="headline">2段目</span>
                  <p>{{ userPerformanceInfoList.performance2Total }}</p>
                  <span class="currency">USD</span>
                </li>
                <li>
                  <span class="headline">3段目</span>
                  <p>{{ userPerformanceInfoList.performance3Total }}</p>
                  <span class="currency">USD</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- ./fv -->
    <div class="list-box">
      <div class="inner">
        <h2>{{ $t("MY NFT") }}</h2>
        <ul class="list">
          <li>
            <p>
              <span>$1,000</span>
              <b>{{ this.userinfo.package1Count }}</b>
            </p>
          </li>
          <li>
            <p>
              <span>$5,000</span>
              <b>{{ this.userinfo.package2Count }}</b>
            </p>
          </li>
          <li>
            <p>
              <span>$10,000</span>
              <b>{{ this.userinfo.package3Count }}</b>
            </p>
          </li>
          <li>
            <p>
              <span>$50,000</span>
              <b>{{ this.userinfo.package4Count }}</b>
            </p>
          </li>
        </ul>
      </div>
    </div>
    <!-- ./list-box -->
    <div class="link-area">
      <div class="inner">
        <div class="link link1">
          <router-link to="/deposit" class="link-over">{{
            $t("DEPOSIT")
          }}</router-link>
        </div>
        <div class="link link2">
          <router-link to="/item" class="link-over">{{
            $t("BUY NFT")
          }}</router-link>
        </div>
      </div>
    </div>
    <!-- ./btn-area -->
  </div>
  <!-- /.contents -->
  <MainFooter />
</template>

<script>
import MainHeader from "@/components/MainHeader.vue";
import MainFooter from "@/components/MainFooter.vue";
import LoginUser from "@/js/loginUser.js";
import accountApi from "@/js/api/account";
export default {
  name: "MypageView",
  components: {
    MainHeader,
    MainFooter,
  },
  data() {
    return {
      status: true,
      userinfo: {
        standardToken: null,
        rp: 0,
        ap: 0,
        package1Count: 0,
        package2Count: 0,
        package3Count: 0,
        package4Count: 0,
      },
      userPerformanceInfoList: {
        performance1: 0,
        performance2: 0,
        performance3: 0,
        performance1Total: 0,
        performance2Total: 0,
        performance3Total: 0,
      },
      isActive: "1",
    };
  },
  created() {
    let token = localStorage.getItem("accessToken");
    if (token != null) {
      this.userinfo = LoginUser.getUserInfo();
    }
    this.getUserPerformanceInfo();
    // console.log(this.userinfo);
  },
  methods: {
    getUserPerformanceInfo() {
      accountApi.GetUserPerformanceInfo().then((res) => {
        if (res) {
          this.userPerformanceInfoList = res.data;
        }
      });
    },
  },
};
</script>
