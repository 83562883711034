import { createRouter, createWebHashHistory } from "vue-router";

import HomeView from "../views/HomeView.vue";
import HomeView2 from "../views/HomeView2.vue";
import SingleView from "../views/SingleView.vue";
import SignIn from "../views/SignIn.vue";
import SignUp from "../views/SignUp.vue";
import PasswordReset from "../views/PasswordReset.vue";
import PasswordInput from "../views/PasswordInput.vue";
import WalletView from "../views/WalletView.vue";
import ItemHistory from "../views/ItemHistory.vue";
import TransactionHistory from "../views/TransactionHistory.vue";
import DepositView from "../views/DepositView.vue";
import WithdrawView from "../views/WithdrawView.vue";
import UsdtTransfer from "../views/UsdtTransfer.vue";
import ItemTransfer from "../views/ItemTransfer.vue";
import AccountView from "../views/AccountView.vue";
import OrganizationTeam from "../views/OrganizationTeam.vue";
import OrganizationTeam2 from "../views/OrganizationTeam2.vue";
import ReferralTeam from "../views/ReferralTeam.vue";
import StakingView from "../views/StakingView.vue";
import ExchangeView from "../views/ExchangeView.vue";
import MypageView from "../views/MypageView.vue";
// import RateView from "../views/RateView.vue";
import MarketView from "@/views/MarketView.vue";
const routes = [
  {
    path: "/",
    name: "MypageView",
    component: MypageView,
  },
  {
    path: "/item",
    name: "Item",
    component: HomeView,
  },
  {
    path: "/item2",
    name: "Item2",
    component: HomeView2,
  },
  {
    path: "/single",
    name: "Single",
    component: SingleView,
  },
  {
    path: "/signin",
    name: "SignIn",
    component: SignIn,
  },
  {
    path: "/signup",
    name: "SignUp",
    component: SignUp,
  },
  {
    path: "/passwordreset",
    name: "PasswordReset",
    component: PasswordReset,
  },
  {
    path: "/passwordinput",
    name: "PasswordInput",
    component: PasswordInput,
  },
  {
    path: "/wallet",
    name: "WalletView",
    component: WalletView,
  },
  {
    path: "/itemhistory",
    name: "ItemHistory",
    component: ItemHistory,
  },
  {
    path: "/transactionhistory",
    name: "TransactionHistory",
    component: TransactionHistory,
  },
  {
    path: "/deposit",
    name: "DepositView",
    component: DepositView,
  },
  {
    path: "/Withdraw",
    name: "WithdrawView",
    component: WithdrawView,
  },
  {
    path: "/usdttransfer",
    name: "UsdtTransfer",
    component: UsdtTransfer,
  },
  {
    path: "/itemtransfer",
    name: "ItemTransfer",
    component: ItemTransfer,
  },
  {
    path: "/account",
    name: "AccountView",
    component: AccountView,
  },
  {
    path: "/organizationteam",
    name: "OrganizationTeam",
    component: OrganizationTeam,
  },
  {
    path: "/organizationteam2",
    name: "OrganizationTeam2",
    component: OrganizationTeam2,
  },
  {
    path: "/referralteam",
    name: "ReferralTeam",
    component: ReferralTeam,
  },
  {
    path: "/staking",
    name: "StakingView",
    component: StakingView,
  },
  {
    path: "/exchange",
    name: "ExchangeView",
    component: ExchangeView,
  },
  // {
  //   path: "/rate",
  //   name: "RateView",
  //   component: RateView,
  // },
  {
    path: "/market",
    name: "MarketView",
    component: MarketView,
  },
];

const scrollBehavior = (to, from, savedPosition) => {
  if (savedPosition) {
    return savedPosition;
  } else {
    return { top: 0 };
  }
};

const router = createRouter({
  base: process.env.BASE_URL,
  history: createWebHashHistory(),
  mode: "history",
  routes,
  scrollBehavior,
});

export default router;
