<template>
  <MainHeader :status="status" />
  <div class="contents transfer-page bg-back">
    <div class="page-ttl">
      <div class="inner">
        <h1>{{ $t("TRANSFER") }}</h1>
        <div class="link">
          <router-link to="/usdttransfer" class="link-over active"
            >CCT</router-link
          >
          <!-- <router-link to="/itemtransfer" class="link-over">ITEM</router-link> -->
        </div>
      </div>
    </div>
    <div class="box">
      <div class="inner">
        <div class="balance-area">
          <p>{{ $t("BALANCE") }}</p>
          <div class="label">
            <b>{{ rp }}</b>
            <span>{{ $t("RP") }}</span>
          </div>
        </div>
        <div class="form-area">
          <p>{{ $t("TRANSFER DETAIL UsdtTransfer") }}</p>
          <form>
            <div class="input-area">
              <input
                type="text"
                name=""
                :placeholder="$t('USER NAME')"
                v-model="userName"
              />
            </div>
            <div class="input-area">
              <input
                type="number"
                name=""
                :placeholder="$t('AMOUNT')"
                v-model="amount"
              />
            </div>
            <div class="btn">
              <button
                type="button"
                class="link-over"
                v-on:click="show"
                ref="btnRef"
                disabled="true"
              >
                {{ $t("TRANSFER") }}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <!-- ./ -->
    <vue-final-modal
      name="modal"
      v-model="showModal"
      :resizable="true"
      :adaptive="true"
      :scrollable="true"
      width="100%"
      height="auto"
    >
      <div class="modal-box usdt-modal">
        <p>{{ $t("Are you sure you want to run it?") }}</p>
        <div class="flex">
          <div class="detail">
            <p>{{ $t("TO") }}</p>
            <div class="label">{{ this.userName }}</div>
          </div>
          <div class="detail">
            <p>{{ $t("AMOUNT") }}</p>
            <div class="label amount">
              {{ this.amount }}<span>{{ $t("RP") }}</span>
            </div>
          </div>
        </div>
        <div class="btn">
          <button type="button" class="link-over mb-10" v-on:click="hideNo">
            {{ $t("NO") }}
          </button>
          <button type="button" class="link-over" v-on:click="hide">
            {{ $t("YES") }}
          </button>
        </div>
      </div>
    </vue-final-modal>
  </div>
  <!-- /.contents -->
  <MainFooter />
</template>
<style lang="less" scoped>
.mb-10 {
  margin-bottom: 10px;
}
</style>
<script>
import MainHeader from "@/components/MainHeader.vue";
import MainFooter from "@/components/MainFooter.vue";
import Asset from "@/js/api/asset.js";
import LoginUser from "@/js/loginUser.js";
import { Notify } from "vant";
export default {
  name: "usdtTransfer",
  components: {
    MainHeader,
    MainFooter,
  },
  data() {
    return {
      status: true,
      showModal: false,
      rp: 0,
      userName: "",
      amount: "",
    };
  },
  created() {
    this.rp = LoginUser.getRp();
  },
  watch: {
    userName(value) {
      if (value != "" && this.amount != "") {
        this.$refs.btnRef.disabled = false;
        this.$refs.btnRef.style = "background-color:#7ed7ff !important";
      } else {
        this.$refs.btnRef.style = "background-color:#e3e4e4 !important";
        this.$refs.btnRef.disabled = true;
      }
    },
    amount(value) {
      if (value != "" && this.userName != "") {
        this.$refs.btnRef.disabled = false;
        this.$refs.btnRef.style = "background-color:#7ed7ff !important";
      } else {
        this.$refs.btnRef.style = "background-color:#e3e4e4 !important";
        this.$refs.btnRef.disabled = true;
      }
    },
  },
  mounted() {
    this.$refs.btnRef.style = "background-color:#e3e4e4 !important";
  },
  methods: {
    show: function () {
      this.$vfm.show("modal");
    },
    hide: function () {
      this.pointToUser();
      this.$vfm.hide("modal");
    },
    hideNo() {
      this.$vfm.hide("modal");
    },
    pointToUser() {
      if (!Number(this.amount)) {
        this.amount = "";
      }
      let data = {
        AssetType: "RP",
        ToUser: this.userName,
        Point: Number(this.amount),
        //PayPassword: this.password,
      };
      Asset.pointToUser(data).then((res) => {
        if (res) {
          Notify({
            type: "success",
            message: "success",
          });
          this.userName = "";
          this.amount = "";
          setTimeout(function () {
            window.location.reload();
          }, 2000);
          // this.getToUserList();
          // this.setPlateInfo();
        }
      });
    },
  },
};
</script>
