import Fetch from "@/js/api/fetch";

const Withdrawal = {
  addWithdrawalAsync: (data) => Fetch.post("/Withdrawal", data),

  getWithdrawalAsync: (data) =>
    Fetch.get("/Withdrawal", {
      params: data,
    }),

  getPricesAsync: () => Fetch.get("/Withdrawal/Prices"),
};
export default Withdrawal;
