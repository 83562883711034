<template>
  <MainHeader :status="status" />
  <div class="contents team-page bg-back">
    <div class="page-ttl">
      <div class="inner">
        <h1>TEAM</h1>
        <div class="link">
          <router-link to="/organizationteam" class="link-over active"
            >ORGANIZATION</router-link
          >
          <router-link to="/referralteam" class="link-over"
            >REFERRAL</router-link
          >
        </div>
      </div>
    </div>
    <div class="organization-box">
      <div class="inner">
        <div class="info-area">
          <div class="wrap">
            <p>YOUR TEAM</p>
            <div class="label"><span>101</span>USER</div>
          </div>
          <div class="wrap">
            <p>YOUR REFERRAL</p>
            <div class="label"><span>100</span>USER</div>
          </div>
          <div class="wrap">
            <p>TEAM OUTCOME</p>
            <div class="label"><span>1000</span>USD</div>
          </div>
        </div>
        <div class="table-area">
          <h2>TEAM <span>> TEST USER1</span></h2>
          <table class="table">
            <tr>
              <th>USER NAME</th>
              <th>STATUS</th>
              <th>REFERRAL</th>
              <th>AMOUNT</th>
            </tr>
            <tr>
              <td class="name">TEST USER1-1</td>
              <td class="status">ACTIVE</td>
              <td>10</td>
              <td class="amount">10,000</td>
            </tr>
            <tr>
              <td class="name">TEST USER1-2</td>
              <td class="status not-active">NOT ACTIVE</td>
              <td class="not-active"></td>
              <td class="amount not-active"></td>
            </tr>
            <tr>
              <td class="name">TEST USER1-3</td>
              <td class="status not-active">NOT ACTIVE</td>
              <td class="not-active"></td>
              <td class="amount not-active"></td>
            </tr>
            <tr>
              <td class="name">TEST USER1-4</td>
              <td class="status">ACTIVE</td>
              <td>10</td>
              <td class="amount">10,000</td>
            </tr>
          </table>
        </div>
      </div>
    </div>
    <!-- ./ -->
  </div>
  <!-- /.contents -->
  <MainFooter />
</template>

<script>
import MainHeader from "@/components/MainHeader.vue";
import MainFooter from "@/components/MainFooter.vue";

export default {
  name: "OrganizationTeam",
  components: {
    MainHeader,
    MainFooter,
  },
  data() {
    return {
      status: true,
    };
  },
};
</script>
