import Axios from "axios";
import Common from "../common";

const Token = {
  postAsync: (data, showLoginError) =>
    Axios.post(`${Common.generateApiUrl("token")}`, data).catch((error) => {
      Common.handleError(error, null, showLoginError);
    }),

  twoFactorAsync: (data) =>
    Axios.post(`${Common.generateApiUrl("token/twoFactor")}`, data).catch(
      (error) => {
        Common.handleError(error, null, true);
      }
    ),
};

export default Token;
