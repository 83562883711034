import Definitions from "./definitions";

const LoginUser = {
  getUserInfo: () =>
    JSON.parse(sessionStorage.getItem(Definitions.StorageKeys.userInfo)),

  setUserInfo: (userInfo) =>
    sessionStorage.setItem(
      Definitions.StorageKeys.userInfo,
      JSON.stringify(userInfo)
    ),

  getUserName: () => LoginUser.getUserInfo()?.userName,

  getap: () => LoginUser.getUserInfo()?.ap,

  getRp: () => LoginUser.getUserInfo()?.rp,

  getEmail: () => LoginUser.getUserInfo()?.email,

  getPhoneNumber: () => LoginUser.getUserInfo()?.phoneNumber,

  getStandardToken: () => LoginUser.getUserInfo()?.standardToken,

  setEmail: (email) => {
    let userInfo = LoginUser.getUserInfo();
    userInfo.email = email;
    LoginUser.setUserInfo(userInfo);
  },

  getReferralCode: () => LoginUser.getUserInfo().referralCode,

  getIntroducer: () => LoginUser.getUserInfo().introducer,

  getFirstName: () => LoginUser.getUserInfo().firstName,

  getUserTitle: () => LoginUser.getUserInfo().userTitle,

  getEmailConfirmed: () => LoginUser.getUserInfo().emailConfirmed,

  isTwoFactorEnabled: () => LoginUser.getUserInfo().twoFactorEnabled,
};

export default LoginUser;
